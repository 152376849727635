import React from 'react';
import classnames from 'classnames';
import bgImage from 'static/images/leaf_get_out_right_now_its_the_end_of_you_and_me.svg';
import { ReactComponent as WaveTop } from 'static/images/layout/wave-top-100.svg';

import styles from './ArticleSingleColumn.module.scss';

export const ArticleSingleColumn = ({ ...props }) => {
  const {
    zIndex,
    headingPart1,
    headingPart2,
    paragraph,
    waveTopFillColour,
  } = props;

  return (
    <section
      className={styles.articlesinglecolumn}
      style={{
        backgroundImage: `url(${bgImage})`,
        zIndex: zIndex ? zIndex : null,
      }}
    >
      <WaveTop
        className={classnames(styles.waveTop, styles[waveTopFillColour])}
      />
      <div className="container">
        <h2 className={styles.heading}>
          {headingPart1}
          <span dangerouslySetInnerHTML={{ __html: headingPart2 }} />
        </h2>
        <p className={styles.paragraph}>{paragraph}</p>
      </div>
    </section>
  );
};

export default ArticleSingleColumn;
