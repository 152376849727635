import React from 'react';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';

import { stripBaseUrl } from 'utils/stripBaseUrl';

import { ReactComponent as WaveTop } from 'static/images/layout/wave-top-300.svg';

import styles from './ArticleSingleFullWidth.module.scss';

export const ArticleSingleFullWidth = ({ ...props }) => {
  // console.log(props);
  const { zIndex, headingPart1, headingPart2, link, paragraph } = props;

  return (
    <section
      className={styles.articlesinglefullwidth}
      style={{ zIndex: zIndex ? zIndex : null }}
    >
      <WaveTop className={styles.waveTop} />
      <div className="container">
        <div className={styles.col}>
          <h2 className={styles.heading}>
            {headingPart1} <span>{headingPart2}</span>
          </h2>
        </div>
        <div className={styles.col}>
          <span className={styles.paragraph}>{paragraph}</span>
          {link.target === '_blank' ? (
            <a
              href={link.url}
              target="_blank"
              className={styles.link}
              rel="noreferrer noopener"
            >
              {link.title} <Icon name="arrow" className={styles.arrow} />
            </a>
          ) : (
            <Link to={stripBaseUrl(link.url)} className={styles.link}>
              {link.title} <Icon name="arrow" className={styles.arrow} />
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default ArticleSingleFullWidth;
