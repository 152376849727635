import React from 'react';

import Button from 'components/Button';
import Listing from './Listing';

import styles from './Listings.module.scss';

export const Listings = (
  { loading, posts, pageInfo, loadMorePosts },
  ...props
) => {
  return (
    <>
      <section className={styles.listings}>
        {posts.map((post, i) => {
          return (
            <div className={styles.item} key={`listing-${i}`}>
              <Listing post={post.node} />
            </div>
          );
        })}
      </section>
      {pageInfo?.hasNextPage && (
        <div className={styles.loadMoreBtnContainer}>
          <Button onClick={() => loadMorePosts(false, true)}>
            {loading ? 'Loading...' : 'Load More'}
          </Button>
        </div>
      )}
    </>
  );
};

export default Listings;
