import React from 'react';
import classnames from "classnames";

import styles from "./Button.module.scss";

export const Button = ({ className, submit, size, variant, link, external, onClick, capitalized, div, children, target }) => {
  return (
    <>
      {
        link
          ? (
              <a target={target ? target : "_self"} onClick={onClick} href={link}
                { ...( external && { target: '_blank', rel : 'noopener noreferrer' } ) }
                className={classnames(
                  className,
                  [styles.btn],
                  {[styles.btn_md] : size === 'md'},
                  {[styles.btn_lg] : size === 'lg'},
                  {[styles.btn_neutral] : variant === 'neutral'},
                  {[styles.btn_outline] : variant === 'outline'},
                  {[styles.btn_primary] : variant === 'primary'},
                  {[styles.btn_green] : variant === 'green'},
                  {[styles.btn_secondary] : variant === 'secondary'},
                  {[styles.btn_basic] : variant === 'basic'},
                  {[styles.btn_capitalized] : capitalized},
                )}>
                {children}
              </a>
            )
          : submit 
            ? (
              <button onClick={onClick} type="submit" className={classnames(
                className,
                [styles.btn],
                {[styles.btn_md] : size === 'md'},
                {[styles.btn_lg] : size === 'lg'},
                {[styles.btn_neutral] : variant === 'neutral'},
                {[styles.btn_outline] : variant === 'outline'},
                {[styles.btn_primary] : variant === 'primary'},
                {[styles.btn_green] : variant === 'green'},
                {[styles.btn_secondary] : variant === 'secondary'},
                {[styles.btn_basic] : variant === 'basic'},
                {[styles.btn_capitalized] : capitalized},
              )}>
                {children}
              </button>
            )

            : div
              ? (
                <div onClick={onClick} className={classnames(
                  className,
                  [styles.btn],
                  {[styles.btn_md] : size === 'md'},
                  {[styles.btn_lg] : size === 'lg'},
                  {[styles.btn_neutral] : variant === 'neutral'},
                  {[styles.btn_outline] : variant === 'outline'},
                  {[styles.btn_primary] : variant === 'primary'},
                  {[styles.btn_green] : variant === 'green'},
                  {[styles.btn_secondary] : variant === 'secondary'},
                  {[styles.btn_basic] : variant === 'basic'},
                  {[styles.btn_capitalized] : capitalized},
                )}>
                  {children}
                </div>
              )
              : (
                <button onClick={onClick} className={classnames(
                  className,
                  [styles.btn],
                  {[styles.btn_md] : size === 'md'},
                  {[styles.btn_lg] : size === 'lg'},
                  {[styles.btn_neutral] : variant === 'neutral'},
                  {[styles.btn_outline] : variant === 'outline'},
                  {[styles.btn_primary] : variant === 'primary'},
                  {[styles.btn_green] : variant === 'green'},
                  {[styles.btn_secondary] : variant === 'secondary'},
                  {[styles.btn_basic] : variant === 'basic'},
                  {[styles.btn_capitalized] : capitalized},
                )}>
                  {children}
                </button>
              )
      }
     
    </>
  )
}

export default Button;
