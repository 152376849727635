import React, { useState, useEffect, useRef } from 'react';
// import { withApollo } from 'react-apollo';
// import gql from 'graphql-tag';
import classnames from 'classnames';
import Picker from 'rmc-picker';
import 'rmc-picker/assets/index.css';
import { useWindowWidth } from '@react-hook/window-size';
import { animateScroll as scroll, scroller, Link } from 'react-scroll';

// components
import Map from 'components/Map';
import MapLocation from 'components/MapLocation';

//import locationMapAsset from 'assets/images/content/location-map.jpg';

import styles from './Amenities.module.scss';
import './Amenities.scss';


const addUniqueId = (slug, array) => {
  return array
    ? array.map((item, i) => ({
        ...item,
        id: item.id || `${slug}-${i}`,
      }))
    : [];
};

export const Amenities = ({
  zoom,
  amenitiesListParentStyle,
  amenitiesListRef,
  amenitiesListStyle,
  amenitiesCategories,
  amenitiesDescription,
  amenitiesMapImage,
  amenitiesTitle,
  locationDescription,
  locationGoogleMapUrl,
  locationHeading,
  locationMap,
  locationSubHeading,
  locationTitle,
  slug,
  ...props
}) => {

  const pathname = window.location.pathname;

  // useEffect(() => {
  //   console.log(props)
  // }, []); // eslint-disable-line

  const markersList = useRef();
  const markersListRef = ref => {
    if (amenitiesListRef) amenitiesListRef.current = ref;
    return (markersList.current = ref);
  };
  const [activeCategory, setActiveCategory] = useState(0);
  //const [selectedCat, setSelectedCat] = useState(0);

  const category = amenitiesCategories ? amenitiesCategories[activeCategory] || {} : null;
  const levels = amenitiesMapImage
    ? [
        {
          image: amenitiesMapImage.mediaItemUrl,
          markers: addUniqueId(
            category.amenitiesCategoryName,
            category.amenities,
          ),
          colour: category.amenitiesCategoryColour,
        },
      ]
    : null;

  const width = useWindowWidth();
  const [level] = levels ? levels : [];
  const [marker] = level ? level.markers : [];
  const [activeAmenity, setActiveAmenity] = useState(marker);

  const changeCategory = i => () => {
    if (props) {
      setActiveCategory(i);
      const category = amenitiesCategories[i];
      const amenity = addUniqueId(category.name, category.amenities)[0];
      setActiveAmenity(amenity);
    }
  };

  const selectChange = e => {
    setActiveCategory(e.target.value);
  };

  useEffect(() => changeCategory(0)(), [amenitiesCategories]); // eslint-disable-line

  const isMobileScreen = width < 1024;

  const onMarkerClick = selectedMarker => {
    if (markersList.current) {
      const { childNodes } = markersList.current;
      const index = level.markers.findIndex(
        marker => marker.id === selectedMarker.id,
      );
      const element = childNodes[index];
      console.dir(element.offsetTop);

      scroll.scrollTo(element.offsetTop, {
        duration: 1200,
        delay: 100,
        smooth: 'easeOutQuint',
        containerId: 'ta-markers-list',
      });
    }

    setActiveAmenity(selectedMarker);
  };

  useEffect(() => {
    scroll.scrollTo(0, { duration: 0 });
  }, [pathname, amenitiesMapImage]);

  useEffect(() => {
    if (pathname.toLowerCase() === '/location') {
      setTimeout(() => {
        scroller.scrollTo('location-map', {
          duration: 1000,
          smooth: true,
          offset: -176,
        });
      }, 200);
    } else {
      scroll.scrollTo(0, { duration: 0 });
    }
  }, [pathname, amenitiesCategories]);
  
  if (!activeAmenity) return null;

  return (
    <section className={classnames(styles.amenities, 'amenities','wrap__hundred')}>
      <div className="wrap__max">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.container)} id="amenities">
            {isMobileScreen ? (
              <>
                <h3 className={classnames(styles.title)}>{amenitiesTitle}</h3>
                <Link
                  to="location-map"
                  smooth={true}
                  offset={-104}
                  duration={500}
                >
                  <h3 className={classnames(styles.title)}>Location</h3>
                </Link>
                <div className={styles.categories}>
                  <div className={styles.select}>
                    <select onChange={selectChange} value={activeCategory}>
                      {amenitiesCategories.map((category, i) => {
                        const active = activeCategory === i;

                        return (
                          <option
                            key={`categories-mobile-${i}`}
                            className={classnames({ [styles.active]: active })}
                            value={i}
                          >
                            {category.amenitiesCategoryName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </>
            ) : null}

            <Map
              className={classnames(styles.map)}
              levels={levels}
              activeMarker={activeAmenity}
              onMarkerClick={onMarkerClick}
              zoom={false}
              bgcolor="#FCCDBE"
              legend={false}
            />

            <div className={classnames(styles.mapnav)}>
              <div className={classnames(styles.content)}>
                {!isMobileScreen ? (
                  <>
                    <h3 className={classnames(styles.title)}>
                      {' '}
                      {amenitiesTitle}{' '}
                    </h3>
                    <Link
                      to="location-map"
                      smooth={true}
                      offset={-176}
                      duration={500}
                    >
                      <h3 className={classnames(styles.title)}> Location </h3>
                    </Link>
                    <div className={styles.categories}>
                      <ul>
                        {amenitiesCategories.map((category, i) => {
                          const active = activeCategory === i;
                          return (
                            <li
                              key={`categories-desktop-${i}`}
                              className={classnames({
                                [styles.active]: active,
                              })}
                              onClick={changeCategory(i)}
                            >
                              {category.amenitiesCategoryName}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                ) : null}

                <div className={classnames(styles.picker)}>
                  {isMobileScreen ? (
                    <Picker
                      selectedValue={activeAmenity.id}
                      onValueChange={value => {
                        const amenity = level.markers.find(
                          amenity => amenity.id === value,
                        );
                        if (amenity) setActiveAmenity(amenity);
                      }}
                    >
                      {level.markers.map((amenity, i) => (
                        <Picker.Item key={amenity.id} value={amenity.id}>
                          {amenity.amenityName.replace('<br/>', ' ')}
                        </Picker.Item>
                      ))}
                    </Picker>
                  ) : (
                    <ul
                      ref={markersListRef}
                      id="ta-markers-list"
                      style={amenitiesListStyle}
                    >
                      {level
                        ? level.markers.map((amenity, i) => {
                            const { amenityName } = amenity;
                            const active = activeAmenity.id === amenity.id;

                            return (
                              <li
                                key={`amenity-${i}`}
                                className={classnames(
                                  { [styles.active]: active },
                                  styles.listitem,
                                )}
                                onClick={() => setActiveAmenity(amenity)}
                              >
                                <button>
                                  <span
                                    className={classnames(
                                      styles.listitem_number,
                                    )}
                                  >
                                    {i < 9 && '0'}
                                    {i + 1}
                                  </span>
                                  <span
                                    className={classnames(
                                      { [styles.active]: active },
                                      styles.listitem_name,
                                    )}
                                    dangerouslySetInnerHTML={{
                                      __html: amenityName,
                                    }}
                                  />
                                </button>
                              </li>
                            );
                          })
                        : null}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
          {
            // console.log(locationMap)
          }
          <div className={classnames(styles.container)}>
            <MapLocation
              heading={locationHeading}
              subHeading={locationSubHeading}
              copy={locationDescription}
              map={locationMap.mediaItemUrl}
              googleMapLink={locationGoogleMapUrl}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Amenities;
