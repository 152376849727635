import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';

import styles from './ArticlePagination.module.scss';

export const ArticlePagination = ({ prevArticle, nextArticle }) => {
  return (
    <section className={styles.articlePagination}>
      <div className={styles.inner}>
        {prevArticle ? (
          <Link
            to={'/news-and-events/' + prevArticle.node.slug}
            className={styles.prev}
          >
            <span className={styles.prev__articleTag}>
              Previous <br className={styles.showMob} />
              Article
            </span>
            <div className={styles.prev__content}>
              <h4>{prevArticle.node.title}</h4>
            </div>
            <div className={styles.prev__arrow}>
            <Icon name="prevArrow" />
            </div>
          </Link>
        ) : null}
        {nextArticle ? (
          <Link
            to={'/news-and-events/' + nextArticle.node.slug}
            className={styles.next}
            style={{
              backgroundImage: `url(${nextArticle.node.featuredImage.mediaItemUrl})`,
            }}
          >
            <span className={styles.next__articleTag}>
              Next <br className={styles.showMob} />
              Article
            </span>
            <div className={styles.next__content}>
              <h4>{nextArticle.node.title}</h4>
            </div>
            <div className={styles.next__overlay}></div>
            <div className={styles.next__arrow}>
             <Icon name="nextArrow" />
            </div>
          </Link>
        ) : null}
      </div>
    </section>
  );
};

export default ArticlePagination;
