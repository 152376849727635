import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import VirtualIcon from 'static/images/virtual-tour.svg';
import Button from 'components/Button';
import styles from './BuilderCard.module.scss';

export default function BuilderCard({ builder }) {
  const builderFields = builder?.builderFields;
  return (
    <Link to={`/builder/${builder?.slug}`}>
    <div className={styles.card}>
      {builderFields?.threeSixtyView && (
        <div className={styles.iconWrapper}>
          <img className={styles.icon} src={VirtualIcon} alt="icon" />
        </div>
      )}
      <div className={styles.logoWrapper}>
        {builderFields?.logo?.mediaItemUrl && (
          <div className={styles.logo}>
            <img src={builderFields.logo.mediaItemUrl} alt="icon" />
          </div>
        )}
      </div>
      <hr className={styles.divider} />
      <p className={styles.subtitleText}>{builder?.title}</p>
      {builderFields?.status && (
        <p
          className={classnames(
            styles.description,
            builderFields.status.toLowerCase() === 'special offer'
              ? styles.specialOffer
              : null,
          )}
        >
          {builderFields.status}
        </p>
      )}
      <Button
        className={styles.viewMore}
        variant="green"
        link={`/builder/${builder?.slug}`}
      >
        VIEW MORE
      </Button>
    </div>
    </Link>
  );
}
