export const toComponentName = text => {
  // convert "_" to spaces
  text = text.split("_").join(" ");
  // convert "-" to spaces
  text = text.split("-").join(" ");
  return text
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join("")
    .replace("PageAcfBlocks", "");
};