import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { do_inSiteReg } from 'utils/insite';
import $ from 'jquery';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { ThemeContext } from 'theme/theme';

import styles from './TiktokCampaignLandingContent.module.scss';

export const TiktokCampaignLandingContent = props => {
  const {
    heading,
    details,
    pageIllustrator,
    refral,
    tiktok,
    termsAndConditions,
    button,
    facebookLink,
    instagramLink,
    formid,
  } = props;

  const [submitting, setSubmitting] = useState(false);
  const { title, url } = button;

  const { register, handleSubmit, errors, watch } = useForm();

  const watchAllFields = watch();
  const {
    tiktokCampaignMailSent,
    updateTiktokCampaignMailSent,
    toggleRefralModalForm,
  } = useContext(ThemeContext);
  const baseUrl = window.location.origin;

  const onSubmit = data => {
    setSubmitting(true);
    const cf7ApiUrl = `${
      baseUrl.includes('localhost')
        ? 'http://wiltonstaging.wpengine.com'
        : baseUrl
    }/wp-json/contact-form-7/v1/contact-forms/${formid}/feedback`;
    var form = new FormData();
    form.append('firstName', data.firstName);
    form.append('lastName', data.lastName);
    form.append('email', data.email);
    form.append('phoneNumber', data.phoneNumber);
    form.append('postcode', data.postcode);
    form.append('looking_for', data.looking_for);
    form.append('purchase_timeframe', data.purchase_timeframe);
    form.append('tiktokLandingPage', data.tiktokLandingPage);

    var settings = {
      url: cf7ApiUrl,
      method: 'POST',
      processData: false,
      mimeType: 'multipart/form-data',
      contentType: false,
      data: form,
    };

    $.ajax(settings)
      .done(async () => {
        updateTiktokCampaignMailSent();
        await do_inSiteReg(data);
      })
      .fail(e => {
        console.log('error', e);
      })
      .always(() => {
        setSubmitting(false);
      });
  };

  return (
    <section className={classnames('regForm', styles.tiktokLandingWrapper)}>
      <div className="container">
        <div className={styles.tiktokLandingContainer}>
          {pageIllustrator?.mediaItemUrl && (
            <img
              src={pageIllustrator.mediaItemUrl}
              className={styles.pageIllustration}
              alt="illustration"
            />
          )}
          {heading && !tiktokCampaignMailSent && <h2>{heading}</h2>}
          {details && <p dangerouslySetInnerHTML={{ __html: details }} />}
          {!tiktokCampaignMailSent && (
            <div className={styles.registerForm}>
              <h3>Simply register today to enter and win!</h3>
              <form
                className={classnames(styles.form)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className={classnames(styles.form_content)}>
                  <div
                    className={classnames(styles.thefields, {
                      [styles.active]: !tiktokCampaignMailSent,
                    })}
                  >
                    <div className={styles.formgroup}>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        autoComplete="off"
                        className={classnames(styles.input, {
                          [styles.errorField]: errors.firstName,
                          [styles.active]: watchAllFields.firstName,
                        })}
                        ref={register({ required: true })}
                      />
                      <label
                        className={classnames(styles.label)}
                        htmlFor="firstName"
                      >
                        First Name*
                      </label>
                      <div className={styles.errorMssg}>
                        {errors.firstName && 'First name is required.'}
                      </div>
                    </div>

                    <div className={styles.formgroup}>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        autoComplete="off"
                        className={classnames(styles.input, {
                          [styles.errorField]: errors.lastName,
                          [styles.active]: watchAllFields.lastName,
                        })}
                        ref={register({ required: true })}
                      />
                      <label
                        className={classnames(styles.label)}
                        htmlFor={`lastName`}
                      >
                        Last Name*
                      </label>
                      <div className={styles.errorMssg}>
                        {errors.lastName && 'Last name is required.'}
                      </div>
                    </div>

                    <div className={styles.formgroup}>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        className={classnames(styles.input, {
                          [styles.errorField]: errors.email,
                          [styles.active]: watchAllFields.email,
                        })}
                        ref={register({
                          required: true,
                          pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        })}
                      />
                      <label
                        className={classnames(styles.label)}
                        htmlFor={`email`}
                      >
                        Email*
                      </label>
                      <div className={styles.errorMssg}>
                        {errors.email && 'Email is required.'}
                      </div>
                    </div>
                    <div className={styles.twoColInput}>
                      <div className={styles.formgroup}>
                        <input
                          type="text"
                          name="phoneNumber"
                          id={`phoneNumber`}
                          autoComplete="off"
                          className={classnames(styles.input, {
                            [styles.errorField]: errors.phoneNumber,
                            [styles.active]: watchAllFields.phoneNumber,
                          })}
                          ref={register({
                            required: true,
                            pattern: /^(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$/,
                          })}
                        />
                        <label
                          className={classnames(styles.label)}
                          htmlFor={`phoneNumber`}
                        >
                          Phone Number*
                        </label>
                        <div className={styles.errorMssg}>
                          {errors.phoneNumber && 'Phone number is required.'}
                        </div>
                      </div>
                      <div className={styles.formgroup}>
                        <input
                          type="text"
                          name="postcode"
                          id={`postcode`}
                          autoComplete="off"
                          className={classnames(styles.input, {
                            [styles.errorField]: errors.postcode,
                            [styles.active]: watchAllFields.postcode,
                          })}
                          ref={register({ required: true })}
                        />
                        <label
                          className={classnames(styles.label)}
                          htmlFor={`postcode`}
                        >
                          Postcode*
                        </label>
                        <div className={styles.errorMssg}>
                          {errors.postcode && 'Postcode is required.'}
                        </div>
                      </div>
                    </div>
                    <div className={styles.selectdropdowngroup}>
                      <select
                        name="looking_for"
                        id="looking_for"
                        autoComplete="off"
                        className={classnames(styles.select, {
                          [styles.errorField]: errors.looking_for,
                          [styles.active]: watchAllFields.looking_for,
                        })}
                        ref={register({
                          required: true,
                        })}
                      >
                        <option value="" hidden></option>
                        <option value="Land Only">Land Only</option>
                        <option value="The Wilton Collection">
                          The Wilton Collection
                        </option>
                        <option value="House & Land">House & Land</option>
                        <option value="Both Land and House & Land">
                          Both Land and House & Land
                        </option>
                      </select>
                      <label
                        className={classnames(styles.label)}
                        htmlFor={`looking_for`}
                      >
                        I’m looking for*
                      </label>
                      <div className={styles.errorMssg}>
                        {errors.looking_for && 'Looking for is required.'}
                      </div>
                    </div>
                    <div className={styles.selectdropdowngroup}>
                      <select
                        name="purchase_timeframe"
                        id="purchase_timeframe"
                        autoComplete="off"
                        className={classnames(styles.select, {
                          [styles.errorField]: errors.purchase_timeframe,
                          [styles.active]: watchAllFields.purchase_timeframe,
                        })}
                        ref={register({
                          required: true,
                        })}
                      >
                        <option value="" hidden></option>
                        <option value="0-6 months">0-6 months</option>
                        <option value="12 months">12 months</option>
                        <option value="18 months">18 months</option>
                        <option value="2 years">2 years</option>
                        <option value="Unknown">Unknown</option>
                      </select>
                      <label
                        className={classnames(styles.label)}
                        htmlFor="purchase_timeframe"
                      >
                        When do you want to buy?*
                      </label>
                      <div className={styles.errorMssg}>
                        {errors.purchase_timeframe &&
                          'Timing of purchase is required.'}
                      </div>
                    </div>
                    <div className={styles.formgroup}>
                      <input
                        type="checkbox"
                        id="agreeTerms"
                        name="agreeTerms"
                        className={classnames(styles.checkbox, {
                          [styles.errorField]: errors.agreeTerms,
                        })}
                        ref={register({ required: true })}
                      />
                      <label
                        htmlFor={`agreeTerms`}
                        className={styles.agreeTermslabel}
                      >
                        I have read and accepted the Privacy Policy and the
                        Terms of Use.
                        <div className={styles.errorMssg}>
                          {errors.agreeTerms &&
                            'You must accept the Privacy Policy and T&C.'}
                        </div>
                      </label>
                    </div>

                    <input
                      type="hidden"
                      name="tiktokLandingPage"
                      value="Tik Tok Landing Page"
                      ref={register()}
                    />

                    <Button
                      submit
                      className={styles.button}
                      variant="secondary"
                      disabled={submitting}
                      size="md"
                    >
                      {submitting ? 'Submitting...' : 'Submit'}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}

          <ul className={styles.lists}>
            {refral?.icon?.mediaItemUrl && refral?.text && (
              <li
                onClick={e => {
                  e.preventDefault();
                  toggleRefralModalForm();
                }}
              >
                <img
                  src={refral.icon.mediaItemUrl}
                  className={styles.icon}
                  alt=""
                />
                {refral.text}
              </li>
            )}
            {tiktok?.icon?.mediaItemUrl && tiktok?.text && tiktok?.url && (
              <li>
                <a
                  rel="noopener noreferrer"
                  href={tiktok?.url ? tiktok.url : '#'}
                  target="_blank"
                >
                  <img
                    src={tiktok.icon.mediaItemUrl}
                    className={styles.icon}
                    alt=""
                  />
                  {tiktok?.text}
                </a>
              </li>
            )}
          </ul>
          {termsAndConditions && (
            <div
              className={styles.termsConditions}
              dangerouslySetInnerHTML={{ __html: termsAndConditions }}
            />
          )}

          {title && url && (
            <Button
              submit
              className={styles.button}
              variant="primary"
              capitalized
              link={url}
              size="md"
              target="_blank"
            >
              {title}
            </Button>
          )}
          <ul className={styles.socialLinks}>
            {facebookLink && (
              <li>
                <a rel="noopener noreferrer" href={facebookLink}>
                  <Icon name="fb" className={styles.facebook} />
                </a>
              </li>
            )}

            {instagramLink && (
              <li>
                <a rel="noopener noreferrer" href={instagramLink}>
                  <Icon name="instagram" className={styles.instagram} />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TiktokCampaignLandingContent;
