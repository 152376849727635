import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';

import { Helmet } from 'react-helmet';
import gql from 'graphql-tag';

import classnames from 'classnames';
import Listings from 'components/Listings';
import Categories from 'components/Categories';
import ScrollToTop from 'utils/ScrollToTop';

import styles from './newsandevents.module.scss';

/**
 * GraphQL category query that takes a category slug as a filter
 * Returns the posts belonging to the category and the category name and ID
 */
const CATEGORY_QUERY = gql`
  query CategoryQuery($filter: String!, $after: String) {
    posts(first: 12, after: $after, where: { categoryName: $filter }) {
      edges {
        node {
          excerpt
          title
          modified
          link
          slug
          featuredImage {
            mediaItemUrl
          }
          categories {
            nodes {
              name
              slug
              categoryId
            }
          }
          id
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
    categories {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

/**
 * Fetch and display a Category
 */
export const NewsandeventsCategory = props => {
  const [state, setState] = useState({
    loading: false,
    categories: null,
    posts: null,
    pageInfo: null,
  });

  const loadMorePosts = async (requestCancelled, fromLoadMore) => {
    const { match, client } = props;
    const filter = match.params.slug;
    setState({
      ...state,
      loading: true,
    });
    const result = await client.query({
      query: CATEGORY_QUERY,
      variables: {
        filter,
        after: fromLoadMore ? state.pageInfo?.endCursor : null,
      },
    });
    if (!requestCancelled) {
      const categories = result.data.categories;
      const posts = result.data.posts.edges;
      let newPosts = posts;
      if (fromLoadMore && state.posts?.length > 0) {
        newPosts = [...state.posts, ...posts];
      }
      setState({
        categories,
        posts: newPosts,
        pageInfo: result.data?.posts?.pageInfo,
        loading: false,
      });
    }
  }; // eslint-disable-line
  /**
   * Execute the category query, parse the result and set the state
   */

  useEffect(() => {
    let requestCancelled = false;

    // Execute post query, process the response and set the state
    loadMorePosts(requestCancelled);

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  const { posts, categories, loading, pageInfo } = state;

  if (!posts) {
    return null;
  } else {
    return (
      <section className={classnames('container', styles.newsandevents)}>
        <ScrollToTop />
        <Helmet>
          <title itemProp="name" lang="en">
            Wilton Greens | News and Events
          </title>
        </Helmet>
        <div className={styles.headingSmall}>
          <h2 className={'font-base-sm'}>Community News</h2>
        </div>
        <Categories
          heading={'<span class="bold">News</span> and Events'}
          categories={categories}
        />
        <Listings
          loading={loading}
          posts={posts}
          pageInfo={pageInfo}
          loadMorePosts={loadMorePosts}
        />
      </section>
    );
  }
};

export default withApollo(NewsandeventsCategory);
