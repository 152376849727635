import React from 'react';
import classnames from 'classnames';

import Icon from 'components/Icon';
import Wysiwyg from 'components/Wysiwyg';

import styles from './ArticleContent.module.scss';

export const ArticleContent = ({ title, hero, excerpt, copy }) => {
 
  
  return (
    <section className={styles.articleContent}>
      <div className={styles.socials}>
        <h3>Share</h3>
        <div className={styles.icons}>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location}`}
            className={classnames(styles.icon)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name="facebook" />
          </a>
          <a
            href={`mailto:?subject=${title} | Wilton Greens&body=${window.location}`}
            className={classnames(styles.icon)}
          > 
            <Icon name="email" />
          </a>
        </div>
      </div>
      <div className={styles.introduction}>
        <div className={styles.excerpt}>
          <h3 dangerouslySetInnerHTML={{ __html: excerpt }}></h3>
        </div>
        <div className={styles.image}>
          <div
            className={styles.image__inner}
            style={{ backgroundImage: `url(${hero})` }}
          >
          </div>
        </div>
      </div>
      <div className={styles.wysiwyg}>
        <Wysiwyg copy={copy} version={'article'} />
      </div>
    </section>
  );
};

export default ArticleContent;
