import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import Swiper from 'react-id-swiper';
import Button from 'components/Button';

import { ThemeContext } from 'theme/theme';

import styles from './ArticleThreeColumns.module.scss';

import { ReactComponent as WaveTop } from 'static/images/layout/wave-top-300.svg';
import { ReactComponent as WaveBottom } from 'static/images/layout/wave-bottom-300.svg';
import { ReactComponent as NextSlide } from 'static/icons/nextslide.svg';

export const ArticleThreeColumns = ({ ...props }) => {
  const [swiper, setSwiper] = useState(null);

  const { toggleModalForm, setFormTypeLocalGuide } = useContext(ThemeContext);

  const {
    headlinePart1,
    headlinePart1Bold,
    headlinePart2,
    headlinePart2Bold,
    paragraph,
    shapeColour,
    slide,
    downloadCopy,
    downloadForm,
    zIndex,
    illustration,
  } = props;

  const showForm = () => {
    setFormTypeLocalGuide();
    toggleModalForm();
  };

  const params = {
    setWrapperSize: true,
    slidesPerView: 1,
    spaceBetween: 10,
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
    },
    loop: true,
    autoplay: {
      delay: 10000,
    },
    //  effect: "fade",
    // fadeEffect: {
    //   crossFade: true,
    // },
    speed: 400,
  };

  useEffect(() => {
    // swiper.update();
  }, [swiper]);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  //eslint-disable-next-line
  const slides =
    slide &&
    slide.map((slide, i) => {
      return (
        <div className={styles.slide} key={i}>
          <div
            className={styles.thumbnail}
            style={{ backgroundImage: `url(${slide.image.sourceUrl})` }}
          />
          <h4 className={styles.h4}>{slide.headline}</h4>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{ __html: slide.text }}
          />
        </div>
      );
    });

  if (!slides) return null;

  return (
    <section
      className={styles.articlethreecolumns}
      style={{ zIndex: zIndex ? zIndex : null }}
    >
      <WaveTop className={styles.wavetop} style={{ fill: shapeColour }} />
      <div
        className={classnames(styles.content, {
          [styles.invert]: shapeColour === '#FFFFFF',
        })}
        style={{ backgroundColor: shapeColour }}
      >
        {illustration ? (
          <div className={styles.illustration}>
            <img src={illustration.mediaItemUrl} alt="leaf"/>
          </div>
        ) : null}
        <div className="container">
          <div className={styles.left_column}>
            {headlinePart1 || headlinePart2 ? (
              <h2 className={styles.headline}>
                {headlinePart1 && (
                  <span
                    className={classnames(styles.part1, {
                      [styles.bold]: headlinePart1Bold,
                    })}
                  >
                    {headlinePart1}
                  </span>
                )}
                <br />
                {headlinePart2 && (
                  <span
                    className={classnames(styles.part2, {
                      [styles.bold]: headlinePart2Bold,
                    })}
                  >
                    {' '}
                    {headlinePart2}
                  </span>
                )}
              </h2>
            ) : null}
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
            {downloadCopy && downloadForm ? (
              <Button
                size="sm"
                variant="primary"
                onClick={showForm}
                className={styles.register}
              >
                {downloadCopy}
              </Button>
            ) : null}
          </div>
          <div className={classnames(styles.right_column, styles.swiper)}>
            {slide.length > 2 && (
              <Swiper {...params} getSwiper={setSwiper}>
                {slides}
              </Swiper>
            )}
            {slide.length <= 2 && (
              <div className={styles.noSwiper}>{slides}</div>
            )}
          </div>
        </div>
      </div>
      <WaveBottom className={styles.wavebottom} style={{ fill: shapeColour }} />
      {slide.length > 2 ? (
        <NextSlide
          className={classnames('nextslide', styles.nextslide)}
          onClick={goNext}
        />
      ) : null}
    </section>
  );
};

export default ArticleThreeColumns;
