import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import styles from './builder.module.scss';
import classNames from 'classnames';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import HomeCard from 'components/HomeCard';

const BUILDER_QUERY = gql`
  query BuilderQuery($url: String) {
    builderBy(slug: $url) {
      title
      slug
      builderFields {
        threeSixtyView
        homeInfo {
          details
          title
          image {
            mediaItemUrl
            altText
          }
          button {
            title
            url {
              ... on Page {
                slug
              }
            }
          }
        }
        displayHome {
          buttons {
            button {
              title
              url
            }
          }
          details
          title
          openingHours
          image {
            mediaItemUrl
            altText
          }
        }
        logo {
          mediaItemUrl
          altText
        }
        homes {
          ... on Home {
            id
            title
            uri
            slug
            homeFields {
              fieldGroupName
              bed
              bathroom
              firstFloorArea
              garage
              storeys
              groundFloorArea
              height
              lotNo
              lotWidth
              totalArea
              width
              address
              threeSixtyView
              features {
                feature
              }
              slider {
                type
                image {
                  mediaItemUrl
                }
                video
              }
              homeFlyer
              virtualTour
            }
          }
        }
      }
    }
  }
`;

export const Builder = props => {
  const history = useHistory();
  const { match, client } = props;
  const slug = match?.params?.slug;
  const [builderDetails, setBuilderDetails] = useState();

  const fetchData = async () => {
    const result = await client.query({
      query: BUILDER_QUERY,
      variables: {
        url: slug,
      },
    });
    setBuilderDetails(result?.data?.builderBy);
  };

  useEffect(() => {
    fetchData();
  }, [props]); // eslint-disable-line

  const homeInfo = builderDetails?.builderFields?.homeInfo;
  const displayVillage = builderDetails?.builderFields?.displayHome;
  const homes = builderDetails?.builderFields?.homes;
  const builderName = builderDetails?.title;
  const builderSlug = builderDetails?.slug;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.mobileHeading}>
          <BackButton url="/display-village" title="Back to Display Village" />
          {homeInfo?.title && (
            <h2 dangerouslySetInnerHTML={{ __html: homeInfo.title }} />
          )}
        </div>
      </div>
      <div className={styles.imageWrapMobile}>
        {homeInfo?.image?.mediaItemUrl && (
          <img src={homeInfo.image.mediaItemUrl} alt="special-offer" />
        )}
      </div>

      <div className={styles.container}>
        <div className={styles.desktopBackBttn}>
          <BackButton url="/display-village" title="Back to Display Village" />
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.textAndImageBlock}>
          <div className={styles.textWrap}>
            {homeInfo?.title && (
              <h2 dangerouslySetInnerHTML={{ __html: homeInfo.title }}></h2>
            )}
            {homeInfo?.details && (
              <div className={styles.text}>{homeInfo.details}</div>
            )}
            {homeInfo?.button?.title?.toLowerCase() && homeInfo?.button?.url?.slug && (
              <Button
                className={styles.viewBtn}
                variant="basic"
                // link={`/${homeInfo.button.url.slug}`}
                onClick={() =>
                  history.push(`/${homeInfo.button.url.slug}`, {
                    builder: builderName,
                    slug: `/builder/${builderSlug}`,
                  })
                }
              >
                {homeInfo.button.title}
              </Button>
            )}
          </div>
          <div className={styles.imageWrap}>
            {homeInfo?.image?.mediaItemUrl && (
              <img src={homeInfo.image.mediaItemUrl} alt="special-offer" />
            )}
          </div>
        </div>
      </div>

      <div className={styles.mobileHeadingDisplayVillage}>
        <div className={styles.container}>
          {displayVillage?.title && (
            <h2 dangerouslySetInnerHTML={{ __html: displayVillage.title }} />
          )}
        </div>
      </div>
      <div className={styles.displayVillageImageWrapMobile}>
        {builderDetails?.logo?.mediaItemUrl && (
          <img
            src={builderDetails.logo.mediaItemUrl}
            alt="Icon"
            className={styles.iconOverlay}
          />
        )}
        {displayVillage?.image?.mediaItemUrl && (
          <img
            src={displayVillage.image.mediaItemUrl}
            alt="special-offer"
            className={styles.leftImage}
          />
        )}
      </div>
      <div className={styles.container}>
        <div className={classNames(styles.textAndImageBlock, styles.imageLeft)}>
          <div className={styles.imageWrap}>
            {builderDetails?.logo?.mediaItemUrl && (
              <img
                src={builderDetails.logo.mediaItemUrl}
                alt="Icon"
                className={styles.iconOverlay}
              />
            )}
            {displayVillage?.image?.mediaItemUrl && (
              <img
                src={displayVillage.image.mediaItemUrl}
                alt="special-offer"
                className={styles.leftImage}
              />
            )}
          </div>

          <div className={classNames(styles.textWrap, styles.imageLeftText)}>
            {displayVillage?.title && (
              <h2 dangerouslySetInnerHTML={{ __html: displayVillage.title }} />
            )}

            <div className={styles.builderDescription}>
              {displayVillage?.details && (
                <div className={styles.description}>
                  <p>{displayVillage.details}</p>
                </div>
              )}
              {displayVillage?.openingHours && (
                <div className={styles.descriptionText}>
                  <p dangerouslySetInnerHTML={{ __html: displayVillage.openingHours}} />
                </div>
              )}

              {displayVillage?.buttons?.length > 0 && (
                <div className={styles.builderButton}>
                  {displayVillage.buttons.map((item, index) => {
                    return (
                      <Button
                        key={`button-${index}`}
                        variant="default"
                        link={item?.button?.url}
                        target="_blank"
                      >
                        {item?.button?.title}
                      </Button>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {homes?.length > 0 && (
          <div className={styles.builderCard}>
            <div className={styles.titletext}>
              <h2>
                HOMES ON DISPLAY
                <strong>AT WILTON GREENS</strong>
              </h2>
            </div>
            <div className={styles.cardGrid}>
              {homes?.map((home, index) => {
                return (
                  <HomeCard
                    key={`home-${index}`}
                    builder={builderDetails}
                    home={home}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withApollo(Builder);
