import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Button from 'components/Button';
import ScrollToTop from 'utils/ScrollToTop';
import illustration from 'static/images/illustration_large.png';
import Icon from 'components/Icon';
import { do_inSiteReg } from 'utils/insite';
import $ from 'jquery';
import { useForm } from 'react-hook-form';

import styles from './contact.module.scss';

export const Contact = props => {
  const {
    headingPart1,
    headingPart2,
    paragraph,
    additionalInformation1,
    additionalInformation2,
    additionalInformation3,
    directionsLink,
  } = props;

  const { register, handleSubmit, errors, watch } = useForm();
  const [mailsent, setMailsent] = useState(false);

  const watchAllFields = watch();

  const onSubmit = data => {
    let mailData = data;
    setMailsent('loading');
    do_inSiteReg(mailData)
      .then(() => {
        $.post('https://s1481342642.t.eloqua.com/e/f2', {
          firstName: data.firstName,
          lastName: data.lastName,
          name: data.name,
          email: data.email,
          phoneNumber: data.phoneNumber,
          postcode: data.postcode,
          message: data.message,
          elqFormName: 'wilton-greens-contactform',
          elqSiteID: 1481342642,
          elqCustomerGUID: '',
          elqCookieWrite: '0',
          utm_source: data.utm_source,
          utm_medium: data.utm_medium,
          utm_campaign: data.utm_campaign,
          utm_term: data.utm_term,
          utm_content: data.utm_content,
        }).done(data => {
          setMailsent(true);
          window.dataLayer.push({
            event: 'form-successful',
          });
          const cf7ApiUrl =
            'https://wiltongreens.com.au/wp-json/contact-form-7/v1/contact-forms/618/feedback';
          $.post(cf7ApiUrl, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            postcode: data.postcode,
            message: data.message,
          });
        });
      })
      .catch(e => console.log('error', e));
  };

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'title-loaded',
        title: 'Contact us',
      });
    }
  }, []); // eslint-disable-line

  return (
    <section
      className={styles.contact}
      style={{ backgroundImage: `url(${illustration})` }}
    >
      <div className={classnames('container', styles.container)}>
        <div
          className={classnames(styles.text, { [styles.active]: !mailsent })}
        >
          <h2 className={styles.h2}>Contact</h2>
          <h1 className={styles.h1}>
            <strong>{headingPart1} </strong>
            {headingPart2}
          </h1>
          <p className={styles.paragraph}>{paragraph}</p>
        </div>
        <form
          className={classnames(styles.form)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={classnames(styles.form_content)}>
            <div
              className={classnames(styles.thefields, {
                [styles.active]: !mailsent,
              })}
            >
              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="firstName"
                  id={`firstName`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.firstName,
                    [styles.active]: watchAllFields.firstName,
                  })}
                  ref={register({ required: true })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`firstName`}
                >
                  First name*
                </label>
                <div className={styles.errorMssg}>
                  {errors.firstName && 'First name is required.'}
                </div>
              </div>

              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="lastName"
                  id={`lastName`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.lastName,
                    [styles.active]: watchAllFields.lastName,
                  })}
                  ref={register({ required: true })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`lastName`}
                >
                  Last name*
                </label>
                <div className={styles.errorMssg}>
                  {errors.lastName && 'Last name is required.'}
                </div>
              </div>

              <div className={styles.formgroup}>
                <input
                  type="email"
                  name="email"
                  id={`email`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.email,
                    [styles.active]: watchAllFields.email,
                  })}
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                <label className={classnames(styles.label)} htmlFor={`email`}>
                  Email*
                </label>
                <div className={styles.errorMssg}>
                  {errors.email && 'Email is required.'}
                </div>
              </div>

              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="phoneNumber"
                  id={`phoneNumber`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.phoneNumber,
                    [styles.active]: watchAllFields.phoneNumber,
                  })}
                  ref={register({
                    required: true,
                    pattern: /^(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$/,
                  })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`phoneNumber`}
                >
                  Phone number*
                </label>
                <div className={styles.errorMssg}>
                  {errors.phoneNumber && 'Phone number is required.'}
                </div>
              </div>
              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="postcode"
                  id={`postcode`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.postcode,
                    [styles.active]: watchAllFields.postcode,
                  })}
                  ref={register({ required: true })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`postcode`}
                >
                  Postcode*
                </label>
                <div className={styles.errorMssg}>
                  {errors.postcode && 'Postcode is required.'}
                </div>
              </div>

              <div className={styles.formgroup}>
                <textarea
                  name="message"
                  id={`message`}
                  autoComplete="off"
                  className={classnames(styles.input, styles.textarea, {
                    [styles.errorField]: errors.message,
                    [styles.active]: watchAllFields.message,
                  })}
                  ref={register({
                    required: true,
                  })}
                />
                <label className={classnames(styles.label)} htmlFor={`message`}>
                  Message*
                </label>
                <div className={styles.errorMssg}>
                  {errors.message && 'Message is required.'}
                </div>
              </div>

              <div className={styles.formgroup}>
                <input
                  type="checkbox"
                  id={`agreeTerms`}
                  name="agreeTerms"
                  className={classnames(styles.checkbox, {
                    [styles.errorField]: errors.agreeTerms,
                  })}
                  ref={register({ required: true })}
                />
                <label
                  htmlFor={`agreeTerms`}
                  className={styles.agreeTermslabel}
                >
                  I have read and accepted the Privacy Policy and the Terms of
                  Use.
                  <div className={styles.errorMssg}>
                    {errors.agreeTerms &&
                      'You must accept the Privacy Policy and T&C.'}
                  </div>
                </label>
              </div>

              <input
                type="hidden"
                name="elqFormName"
                value="wilton-greens-contactform"
                ref={register()}
              />
              <input
                type="hidden"
                name="elqSiteID"
                value="1481342642"
                ref={register()}
              />
              <input
                type="hidden"
                name="elqCustomerGUID"
                value=""
                ref={register()}
              />
              <input
                type="hidden"
                name="elqCookieWrite"
                value="0"
                ref={register()}
              />

              <Button
                submit
                className={styles.button}
                variant="secondary"
                capitalized
                disabled={mailsent}
                size="md"
              >
                {mailsent === 'loading' ? 'Loading...' : 'Submit'}
              </Button>
            </div>

            <div
              className={classnames(styles.confirmation, {
                [styles.active]: mailsent,
              })}
            >
              <div
                className={classnames(styles.form_heading, styles.formgroup)}
              >
                <h3 className={styles.h3}>
                  Thank you
                  <br />
                  <span>For your interest</span>
                </h3>
                <div className={styles.description}>
                  Thank you for your enquiry. We'll be in contact shortly.
                </div>
              </div>

              {/* <Button div onClick={toggle} className={styles.button} capitalized size="md">Back to Homepage</Button> */}
            </div>
          </div>
        </form>
      </div>

      <div className={classnames('container', styles.container)}>
        <div className={styles.address}>
          <div
            className={styles.left}
            dangerouslySetInnerHTML={{ __html: additionalInformation1 }}
          ></div>
          <div className={styles.right}>
            <div dangerouslySetInnerHTML={{ __html: additionalInformation2 }} />

            <a
              href={directionsLink.url}
              target={directionsLink.target}
              rel="noopener noreferrer"
              className={styles.getdirections}
            >
              {directionsLink.title}{' '}
              <Icon name="marker" className={styles.marker} />
            </a>
          </div>
          <div className={styles.right}>
            {additionalInformation3 && <div dangerouslySetInnerHTML={{ __html: additionalInformation3}}/>}
            <div className={styles.contactSocial}>
              <a
                href="https://www.facebook.com/wiltongreensestate"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="fbGreen" className={styles.social} />
              </a>
              <a
                href="https://www.instagram.com/wiltongreensestate/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="instaGreen" className={styles.social} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </section>
  );
};

export default Contact;
