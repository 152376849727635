import React, { useContext } from 'react';
import classnames from 'classnames';
// import { Link } from 'react-router-dom';
import Swiper from 'react-id-swiper';
import Button from 'components/Button';

import { ThemeContext } from 'theme/theme';

import styles from './HeroSlider.module.scss';

import { ReactComponent as Wave } from 'static/images/layout/wave-down-500.svg';

export const HeroSliderEoi = ({ ...props }) => {
  const {
    headline,
    subheadlinePart1,
    subheadlinePart2,
    buttonLabel,
    buttonTriggerForm,
    showIllustration,
    // ButtonLink,
    shapeColour,
    illustration,
    backgroundImageSlides,
  } = props;

  const { toggleModalForm, setFormTypeRegister } = useContext(ThemeContext);

  const params = {
    autoplay: { delay: 4500 },
    effect: 'fade',
    speed: 1500,
    slidesPerView: 1,
    freeModeMomentum: true,
    loop: true,
    navigation: {
      nextEl: '.hero-swiper-button-next',
      prevEl: '.hero-swiper-button-prev',
      clickable: true,
    },
    renderPrevButton: () => <div className="hero-swiper-button-prev">Prev</div>,
    renderNextButton: () => <div className="hero-swiper-button-next">Next</div>,
  };

  const onClick = () => {
    toggleModalForm();
    setFormTypeRegister();
  };

  // Add eventlisteners for swiper after initializing

  if (!backgroundImageSlides.length || !backgroundImageSlides) return null;

  return (
    <section
      className={styles.heroslidereoi}
      style={{
        backgroundColor: shapeColour,
        backgroundImage: `url(${backgroundImageSlides[0].sourceUrl})`,
      }}
    >
      {backgroundImageSlides.length > 1 ? (
        <div className={classnames(styles.swiperWrapper)}>
          <Swiper {...params}>
            {backgroundImageSlides.map((slide, i) => {
              return (
                <div key={`hero-slider-${i}`}>
                  <div
                    className={styles.slideImage}
                    style={{
                      backgroundColor: shapeColour,
                      backgroundImage: `url(${slide.sourceUrl})`,
                    }}
                  >
                    <Wave className={styles.waveSwiper} />
                  </div>
                </div>
              );
            })}
          </Swiper>
        </div>
      ) : null}

      <div className={classnames('container', styles.container)}>
        <div className={styles.left_column}>
          <h1 className={styles.headline}>{headline}</h1>
          {subheadlinePart1 || subheadlinePart2 ? (
            <h2 className={styles.subheadline}>
              {subheadlinePart1 && (
                <span className={styles.part1}>{subheadlinePart1}</span>
              )}
              {subheadlinePart2 && (
                <span className={styles.part2}> {subheadlinePart2}</span>
              )}
            </h2>
          ) : null}
          <Button
            div
            size="lg"
            variant="primary"
            {...(buttonTriggerForm && { onClick })}
          >
            {buttonLabel}
          </Button>
        </div>
        {showIllustration ? (
          <div className={styles.right_column}>
            <img
              src={illustration.sourceUrl}
              alt=""
              className={styles.illustration}
            />
          </div>
        ) : null}
      </div>
      {backgroundImageSlides.length <= 1 ? (
        <Wave className={styles.wave} />
      ) : null}
    </section>
  );
};

export default HeroSliderEoi;
