import React from 'react';
import classnames from 'classnames';

import styles from './Modal.module.scss';

import { ReactComponent as Close } from './img/close.svg';

export const Modal = ({ isOpen, setOpen, children }) => {
  const toggleModal = () => {
    setOpen(!isOpen);
  };
  return (
    <div className={classnames(styles.modal)}>
      <div className={styles.modal_closearea} onClick={toggleModal} />
      <div
        className={classnames(styles.modal_content, {
          [styles.active]: isOpen,
        })}
      >
        <div className={styles.close} onClick={toggleModal}>
          <Close className={styles.icon} />
        </div>
        <div className={styles.children}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
