import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './Categories.module.scss';

export const Categories = ({ heading, categories }) => {
  const [search, setSearch] = useState();
  const history = useHistory();
  const onChange = event => {
    history.push(`/category/${event.target.value}`);
  };

  const handleSearch = event => {
    if (event.keyCode === 13) {
      history.push(`/search/${search.filter}`);
    }
    return true;
  };

  

  return (
    <section className={styles.categories}>
      <div className={styles.left}>
        <h1
          className={'headings-font-display-3'}
          dangerouslySetInnerHTML={{ __html: heading }}
        ></h1>
      </div>
      <div className={styles.right}>
        <h3 className={'font-base-sm bold'}>Categories</h3>
        <div className={styles.search}>
          <div className={styles.selectWrapper}>
            <select name="" id="" onChange={onChange}>
              {categories.edges.map((category, i) => {
                return (
                  <option
                    className={'font-base-sm'}
                    key={`Category-dropdown-${i}`}
                    value={category.node.slug}
                  >
                    {category.node.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className={styles.inputWrapper}>
            <input
              onChange={event => setSearch({ filter: event.target.value })}
              onKeyDown={handleSearch}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categories;
