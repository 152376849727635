import React from 'react';

import * as iconsComponents from './IconsList';

export const Icon = ({ name, className, onClick }) => {

  const Component = iconsComponents[name];

  if (!name) return null;

  return (
    <Component className={ className } onClick={ onClick } />
  );
};

export default Icon;
