import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';
import Picker from 'rmc-picker';
import useWindowSize from '@react-hook/window-size';

import { animateScroll as scroll } from 'react-scroll';
import { ScrollToTop } from 'utils/ScrollToTop';

// components
import Map from 'components/Map';

import 'rmc-picker/assets/index.css';

import styles from './Masterplan.module.scss';

const addUniqueId = (slug, array) => {
  if (!array) return null;
  return array.map((item, i) => ({
    ...item,
    id: item.id || `${slug}-${i}`,
  }));
};

export const Masterplan = props => {
  const {
    // description,
    title,
    subTitle,
    mapImage,
    mapDownload,
    locations,
  } = props;

  const [width] = useWindowSize();

  const levels = [
    {
      image: mapImage ? mapImage.mediaItemUrl : null,
      markers: addUniqueId('location', locations ? locations : null),
    },
  ];

  const markersList = useRef();
  const [level] = levels || [];
  const [marker] = level.markers || [];
  const [activeLocation, setActiveLocation] = useState(marker);

  const isMobileScreen = width < 1024;

  const onMarkerClick = selectedMarker => {
    if (markersList.current) {
      const { childNodes } = markersList.current;
      const index = level.markers.findIndex(
        marker => marker.id === selectedMarker.id,
      );
      const element = childNodes[index];

      scroll.scrollTo(element.offsetTop, {
        duration: 1200,
        delay: 100,
        smooth: 'easeOutQuint',
        containerId: 'ta-markers-list',
      });
    }

    setActiveLocation(selectedMarker);
  };

  useEffect(() => {
    // Reset selected marker

    setActiveLocation({
      icon: false,
      name: '',
      colour: '',
      id: '',
      coordinates: [
        {
          x: '0',
          y: '0',
        },
      ],
    });

    if (level.markers) {
      setTimeout(() => setActiveLocation(level.markers[0]), 10);
    }

  }, []); // eslint-disable-line

  return (
    <section className={classnames(styles.masterplan)}>
      <ScrollToTop />
      <div className="wrap__max">
        <div className={classnames(styles.inner)}>
          <div className={classnames(styles.container)}>
            {isMobileScreen ? (
              <>
                <h4 className={classnames(styles.subTitle)}>{subTitle}</h4>
                <h3 className={classnames(styles.title)}>{title}</h3>
              </>
            ) : null}

            <Map
              className={styles.map}
              levels={levels}
              activeMarker={activeLocation}
              onMarkerClick={onMarkerClick}
              zoom={false}
              bgcolor="#F2F2F2"
              download={mapDownload || null}
            />

            <div className={styles.mapnav}>
              <div className={styles.content}>
                {!isMobileScreen ? (
                  <div className={styles.titles}>
                    <h4 className={classnames(styles.subTitle)}>{subTitle}</h4>
                    <h3 className={classnames(styles.title)}>{title}</h3>
                  </div>
                ) : null}

                <div
                  className={styles.markerslist}
                >
                  {isMobileScreen ? (
                    <Picker
                      selectedValue={activeLocation.id}
                      onValueChange={value => {
                        const amenity = level.markers.find(
                          amenity => amenity.id === value,
                        );
                        if (amenity) setActiveLocation(amenity);
                      }}
                    >
                      {level.markers.map((amenity, i) => (
                        <Picker.Item key={amenity.id} value={amenity.id}>
                          {amenity.name}
                        </Picker.Item>
                      ))}
                    </Picker>
                  ) : (
                    <ul ref={markersList} id="ta-markers-list">
                      {level.markers.map((amenity, i) => {
                        const { name } = amenity;
                        const active = activeLocation.id === amenity.id;

                        return (
                          <li
                            key={`amenity-${i}`}
                            className={classnames(
                              { [styles.active]: active },
                              styles.listitem,
                            )}
                            onClick={() => setActiveLocation(amenity)}
                          >
                            <button>
                              <span
                                className={classnames(styles.listitem_number)}
                              >
                                {i < 9 && '0'}
                                {i + 1}
                              </span>
                              <span
                                className={classnames(
                                  { active },
                                  styles.listitem_name,
                                )}
                              >
                                {name}
                              </span>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Masterplan;
