import React, { useState, useEffect } from 'react';
import styles from './CountdownTimer.module.scss';

export const CountdownTimer = ({ endDate }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const getTime = () => {
      const time = Date.parse(endDate) - Date.now();
  
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
    const interval = setInterval(() => getTime(endDate), 1000);

    return () => clearInterval(interval);
  }, [endDate]);
  return (
    <div className={styles.counterWrapper}>
      <div className={styles.counterContainer}>
        <div className={styles.day}>
          <p id="day">{days < 10 ? '0' + days : days}</p>
          <span className="text">Days</span>
        </div>
        <div className={styles.hours}>
          <p id="hour">{hours < 10 ? '0' + hours : hours}</p>
          <span className="text">Hours</span>
        </div>
        <div className={styles.minutes}>
          <p id="minute">{minutes < 10 ? '0' + minutes : minutes}</p>
          <span className="text">Minutes</span>
        </div>
        <div className={styles.seconds}>
          <p id="second">{seconds < 10 ? '0' + seconds : seconds}</p>
          <span className="text">Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
