import React from 'react';
import classnames from 'classnames';

// import { ReactComponent as Leaf } from "static/images/pink-leaf.svg";

import styles from './ArticleTwoColumns.module.scss';

export const ArticleTwoColumns = ({ ...props }) => {
  const { articles, image, zIndex } = props;

  return (
    <section
      className={styles.articletwocolumns}
      style={{
        backgroundImage: image ? `url(${image.sourceUrl})` : null,
        zIndex: zIndex ? zIndex : null,
      }}
    >
      <div className="container">
        <div className={styles.text}>
          {articles.map((article, i) => {
            return (
              <article
                key={i}
                className={classnames(styles.article, {
                  [styles.isIcon]: article.isIcon,
                })}
              >
                {!article.isIcon && (
                  <div
                    className={styles.thumbnail}
                    style={{
                      backgroundImage: `url(${article.image.sourceUrl})`,
                    }}
                  />
                )}
                {article.isIcon && (
                  <img
                    src={article.image.sourceUrl}
                    className={styles.icon}
                    alt=""
                  />
                )}
                <h3>{article.headline}</h3>
                <p>{article.paragraph}</p>
              </article>
            );
          })}
        </div>
      </div>
      {/* <Leaf className={styles.leaf} /> */}
    </section>
  );
};

export default ArticleTwoColumns;
