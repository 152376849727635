import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
// import Swiper from 'react-id-swiper';

import { gsap } from "gsap";
import MorphSVGPlugin from "js/MorphSVGPlugin"

import { ReactComponent as NextSlide } from 'static/icons/nextslide.svg';
import styles from './ArticleFluidImage.module.scss';

gsap.registerPlugin(MorphSVGPlugin);

export const ArticleFluidImage = ({ ...props }) => {
  const {
    margins,
    illustration,
    slide,
    zIndex,
  } = props;

  const [activeIndex, setActiveIndex ] = useState(0);
  const [activeSlide, setActiveSlide ] = useState(0);

  // console.log(props);

  const goToNext = () => {
    // console.log('nextslide!')
    if(activeIndex === slide.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex+1);
    }
  }

  const nextSlide = () => {
    if(activeSlide === slide.length - 1) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide+1);
    }
   gsap.to('.blob .start', {duration: 1, morphSVG: '.blob .end', ease: "elastic.out(1, 0.4)",  onComplete: goToNext});
  }

  useEffect(() => {
    // gsap.to('.blob .start', {duration: 2.5, morphSVG: '.blob .end', ease: "elastic.out(1, 0.4)"});
  }, [])

  const paths = [
    // "M1280,463.892905 C1280,671.30656 983.414634,833 663.414634,833 C343.414634,833 0,671.30656 0,463.892905 C0,255.364123 343.414634,0 663.414634,0 C983.414634,0 1280,255.364123 1280,463.892905 Z",
    // "M1268.20482,449.024096 C1268.20482,740.493976 873.409639,1166.13253 555.722892,1166.13253 C238.036145,1166.13253 0.542168675,740.493976 0.542168675,449.024096 C0.542168675,157.554217 238.036145,0.253012048 555.722892,0.253012048 C873.409639,0.253012048 1268.20482,157.554217 1268.20482,449.024096 Z",
    // "M1264.56044,459.989011 C1264.56044,669.571429 1043.72527,839.769231 727.241758,839.769231 C410.758242,839.769231 0.032967033,669.571429 0.032967033,459.989011 C0.032967033,250.406593 410.758242,0.032967033 727.241758,0.032967033 C1043.72527,0.032967033 1264.56044,250.406593 1264.56044,459.989011 Z",
    // "M1276.57143,586 C1276.57143,831.714286 947.428571,983.714286 627.428571,983.714286 C308.571429,983.714286 0,831.714286 0,586 C0,340.285714 308.571429,0.857142857 627.428571,0.857142857 C947.428571,0.857142857 1276.57143,340.285714 1276.57143,586 Z"
    "M1267.89,631.39c0,279.62,61.23,383.61-370.18,383.61S3.34,911.01,3.34,631.39 C3.34,350.27,466.31,6,897.71,6S1267.89,350.27,1267.89,631.39z",
    "M1269.28,465.02c0,291.47,37.73,549.98-433.97,549.98S145,618.98,145,368 c0-240.02,354-351.75,690.32-351.75C1307.01,16.25,1269.28,173.55,1269.28,465.02z",
    "M1270.19,594.57c0,267.23,64.68,417.43-338.85,417.43S4.11,1020.23,4.11,753 S527.8,8.1,931.34,8.1S1270.19,327.34,1270.19,594.57z",
    "M1262.58,546.11c0,227.64,57.23,452.89-384.83,452.89C437.27,999,162,773.75,162,546.11 S437.27,4,877.75,4C1319.81,4,1262.58,318.46,1262.58,546.11z"
  ]


  // <path className={classnames({
  //   "start" : i === activeIndex,
  //   "end" : (i === activeIndex+1),
//  })} fill="#FF0066" d="M1280,463.892905 C1280,671.30656 983.414634,833 663.414634,833 C343.414634,833 0,671.30656 0,463.892905 C0,255.364123 343.414634,0 663.414634,0 C983.414634,0 1280,255.364123 1280,463.892905 Z" id="Path"></path>,

  const images =
    slide && slide.map((item, i) => {

      // console.log(activeIndex, slide.length);
      return (
        <div className={classnames(styles.image, {
          [styles.active] : i === activeSlide,
          [styles.next] : (i === activeSlide+1 && activeSlide !== slide.length) || (i === 0 && activeSlide === slide.length-1),
        })} key={i}>

          <svg viewBox="0 0 1282.3 1025.84" xmlns="http://www.w3.org/2000/svg" className="blob" preserveAspectRatio="xMinYMid meet" >
            <defs>
              <clipPath id="hero-image-text-mask-top-one">
              {/* <path fill="#FF0066" className="start" d="M36,-3.6C36,17.7,18,35.4,-6.9,35.4C-31.9,35.4,-63.8,17.7,-63.8,-3.6C-63.8,-24.8,-31.9,-49.7,-6.9,-49.7C18,-49.7,36,-24.8,36,-3.6Z" transform="translate(100 100)" /> */}
              {
                paths.map((path, index) => {
                  return (
                    <path key={index} onAnimationEnd={()=> console.log('ani end')} className={classnames({
                        [styles.start] : index === activeIndex,
                        "start" : index === activeIndex,
                        "end" : (index === activeIndex+1 && activeIndex !== slide.length) || (index === 0 && activeIndex === slide.length-1),
                      })}
                      d={path}
                    />
                     
                  )
                })
              }
               
              </clipPath>
            </defs>
            <image
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMid slice"
              xlinkHref={item.fluidImage.sourceUrl}
              clipPath="url(#hero-image-text-mask-top-one)"
            />
          </svg>
        </div>
      )
    })

  const text =
    slide &&
    slide.map((slide, i) => {
      return (
        <div className={classnames(styles.text, {
          [styles.active] : i === activeSlide,
          [styles.next] : (i === activeSlide+1 && activeSlide !== slide.length) || (i === 0 && activeSlide === slide.length-1),
        })} key={i}>
          <img
            src={slide.icon.sourceUrl}
            alt={`${slide.headlinePart1} ${slide.headlinePart2}`}
            className={styles.icon}
          />
          {slide.headlinePart1 || slide.headlinePart2 ? (
            <h3 className={styles.headline}>
              {slide.headlinePart1 && (
                <span className={styles.part1}>{slide.headlinePart1}</span>
              )}
              {slide.headlinePart2 && (
                <span className={styles.part2}> {slide.headlinePart2}</span>
              )}
            </h3>
          ) : null}
          {slide.paragraph && (
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: slide.paragraph }}
            />
          )}
        </div>
      );
    });

  return (
    <section
      className={styles.articleFluidImage}
      style={{
        marginTop: margins.top ? margins.top : null,
        marginBottom: margins.bottom ? margins.bottom : null,
        zIndex: zIndex ? zIndex : null,
      }}
    >
      <div className={classnames('container', styles.container)}>
      
        <div className={styles.slider}>
          <div className={styles.imageSlides}>
           {images}
          </div>
          <div className={styles.textSlides}>
            {text}
          </div>
          <NextSlide onClick={nextSlide} className={styles.nextslide} />
        </div>

      </div>

      <img
        src={illustration.sourceUrl}
        className={styles.illustration}
        alt=""
      />

    </section>
  );
};

export default ArticleFluidImage;
