import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import ScrollToTop from 'utils/ScrollToTop';

import bg from 'static/images/illustration_large.png';
import styles from './legal.module.scss';

const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      content
    }
  }
`;

export const Legal = props => {
 
  const [pageState, setPageState] = useState({
    page: null,
  });

  useEffect(() => {
    let requestCancelled = false;
    const executePageQuery = async () => {
      const { match, client } = props;
      let uri = match.url.substr(1);

      const result = await client.query({
        query: PAGE_QUERY,
        variables: {uri},
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;
        setPageState({ ...pageState, page });
      }
    };

    executePageQuery();

    return () => {
      requestCancelled = true;
    };
  }, [props]);//eslint-disable-line

 
  return (
    <>
    <ScrollToTop />
      <div
        className={styles.legal}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="container">
          {pageState.page ? (
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{ __html: pageState.page.content }}
            />
          ) : null}
         
        </div>
      </div>
    </>
  );
};

export default withApollo(Legal);
