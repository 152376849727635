import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import styles from './Wysiwyg.module.scss';

export const Wysiwyg = ({ copy, version }) => {
  // console.log(copy);

  const [newCopy, setNewCopy] = useState();

  useEffect(() => {
    if (copy && newCopy !== '') {
      let editedCopy = copy.replace(
        '<p><iframe',
        `<p class='has-iframe'><iframe`,
      );
      setNewCopy(editedCopy);
    }
  }, [copy]);//eslint-disable-line

  if(!newCopy) return null;
  return (
    <div
      className={classnames(
        version === 'article' ? styles.article : null,
        styles.wysiwyg,
      )}
      dangerouslySetInnerHTML={{ __html: newCopy }}
    ></div>
  );
};

export default Wysiwyg;
