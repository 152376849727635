import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import Button from 'components/Button';
import { do_inSiteReg } from 'utils/insite';
import $ from 'jquery';

import { ReactComponent as Logo } from './images/logo-white.svg';

import styles from './Ipad.module.scss';

export const Ipad = () => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [mailsent, setMailsent] = useState(false);
  const [splash, setSplash] = useState(true);
  const [firstName, setFirstName] = useState();

  const watchAllFields = watch();

  const newGuest = () => {
    setSplash(true);
    setMailsent(false);
  };

  const onSubmit = (data, e) => {
    setFirstName(data.firstName);
    let mailData = data;
    // setMailsent('loading');
    do_inSiteReg(mailData).then(() => {
      $.post('https://s1481342642.t.eloqua.com/e/f2', {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        postCode: data.postcode,
        buyWithin: data.looking,
        sizeProduct: data.product.toString(),
        elqFormName: eloqua.formName,
        elqSiteID: eloqua.formID,
        elqCustomerGUID: '',
        elqCookieWrite: '0',
      }).done(data => {
        setMailsent(true);
        e.target.reset();
      });
    });
  };

  const registerForm = {
    headlinePart1: 'Register Your Interest',
    headlinePart2:
      'Fill in your details to receive exclusive updates and special offers.',
    headlinePart1Bold: true,
    headlinePart2Bold: false,
    paragraph: '',
    fields: [
      'firstname',
      'lastname',
      'email',
      'phonenumber',
      'postcode',
      'looking',
      'product',
    ],
    buttonLabel: 'Register',
  };

  const registerThankyou = {
    headlinePart1: firstName,
    headlinePart2: 'THANK YOU FOR REGISTERING',
    headlinePart1Bold: true,
    headlinePart2Bold: false,
    paragraph:
      'We’ve sent an email with all the details.<br /> For more information chat to  sales agent.',
    buttonLabel: 'Register next guest',
    download: '',
  };

  const registerEloqua = {
    formID: 1481342642,
    formName: 'wilton-greens-eoi2',
  };

  const form = registerForm;
  const eloqua = registerEloqua;
  const thankyou = registerThankyou;

  // console.log(mailsent);

  useEffect(() => {
    if (mailsent) {
      const timer = setTimeout(() => {
        // console.log('mail sent');
        newGuest();
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [mailsent]);

  return (
    <div className={styles.ipad}>
      <div className={classnames(styles.splash, { [styles.hide]: !splash })}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.intro}>
          <div className={styles.leaf}></div>
          <div className={styles.left}>
            <h4>WELCOME TO WILTON GREENS</h4>
            <h3>
              <span className={styles.bold}>LIVE WITH NATURE</span>
              <br />
              AT SYDNEY’S GATEWAY
            </h3>
          </div>
          <div className={styles.right}>
            <Button
              className={styles.button}
              capitalized
              size="md"
              onClick={() => {
                setSplash(false);
              }}
            >
              Register your Details
            </Button>
          </div>
        </div>
      </div>
      <div></div>
      <form
        className={classnames(styles.form)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div
            className={classnames(styles.thefields, {
              [styles.active]: !mailsent,
            })}
          >
            <div className={classnames(styles.form_heading, styles.form_group)}>
              <h3 className={styles.h3}>
                {form.headlinePart1 && form.headlinePart1}{' '}
              </h3>
              <h4> {form.headlinePart2 && form.headlinePart2}</h4>
              {form.paragraph && (
                <div className={styles.description}>{form.paragraph}</div>
              )}
            </div>

            {form.fields.includes('firstname') && (
              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="firstName"
                  id={`firstName`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.firstName,
                    [styles.active]: watchAllFields.firstName,
                  })}
                  ref={register({ required: true })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`firstName`}
                >
                  First name*
                </label>
                <div className={styles.errorMssg}>
                  {errors.firstName && 'First name is required.'}
                </div>
              </div>
            )}

            {form.fields.includes('lastname') && (
              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="lastName"
                  id={`lastName`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.lastName,
                    [styles.active]: watchAllFields.lastName,
                  })}
                  ref={register({ required: true })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`lastName`}
                >
                  Last name*
                </label>
                <div className={styles.errorMssg}>
                  {errors.lastName && 'Last name is required.'}
                </div>
              </div>
            )}

            {form.fields.includes('name') && (
              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="name"
                  id={`name`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.name,
                    [styles.active]: watchAllFields.name,
                  })}
                  ref={register({ required: true })}
                />
                <label className={classnames(styles.label)} htmlFor={`name`}>
                  Name*
                </label>
                <div className={styles.errorMssg}>
                  {errors.name && 'Name is required.'}
                </div>
              </div>
            )}

            {form.fields.includes('email') && (
              <div className={styles.formgroup}>
                <input
                  type="email"
                  name="email"
                  id={`email`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.email,
                    [styles.active]: watchAllFields.email,
                  })}
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                <label className={classnames(styles.label)} htmlFor={`email`}>
                  Email*
                </label>
                <div className={styles.errorMssg}>
                  {errors.email && 'Email is required.'}
                </div>
              </div>
            )}

            {form.fields.includes('phonenumber') && (
              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="phoneNumber"
                  id={`phoneNumber`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.phoneNumber,
                    [styles.active]: watchAllFields.phoneNumber,
                  })}
                  ref={register({
                    required: true,
                    pattern: /^(\+\d{2}[ -]{0,1}){0,1}(((\({0,1}[ -]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ -]*(\d{4}[ -]{0,1}\d{4}))|(1[ -]{0,1}(300|800|900|902)[ -]{0,1}((\d{6})|(\d{3}[ -]{0,1}\d{3})))|(13[ -]{0,1}([\d -]{5})|((\({0,1}[ -]{0,1})0{0,1}\){0,1}4{1}[\d -]{8,10})))$/,
                  })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`phoneNumber`}
                >
                  Phone number*
                </label>
                <div className={styles.errorMssg}>
                  {errors.phoneNumber && 'Phone number is required.'}
                </div>
              </div>
            )}

            {form.fields.includes('postcode') && (
              <div className={styles.formgroup}>
                <input
                  type="text"
                  name="postcode"
                  id={`postcode`}
                  autoComplete="off"
                  className={classnames(styles.input, {
                    [styles.errorField]: errors.postcode,
                    [styles.active]: watchAllFields.postcode,
                  })}
                  ref={register({
                    required: true,
                  })}
                />
                <label
                  className={classnames(styles.label)}
                  htmlFor={`postcode`}
                >
                  Postcode*
                </label>
                <div className={styles.errorMssg}>
                  {errors.postcode && 'Postcode is required.'}
                </div>
              </div>
            )}
            {form.fields.includes('looking') && (
              <div
                className={classnames(
                  styles.formgroup,
                  styles.formgroup__looking,
                )}
              >
                <label className={classnames(styles.looking__label)}>
                  I’m looking to buy in the next?
                </label>
                <div className={styles.check}>
                  {' '}
                  <input
                    type="radio"
                    name="looking"
                    value={'0-1'}
                    ref={register({ required: true })}
                  />
                  <div>0 - 1 mth</div>
                </div>

                <div className={styles.check}>
                  {' '}
                  <input
                    type="radio"
                    name="looking"
                    value={'1-3'}
                    ref={register({ required: true })}
                  />
                  <div>1 - 3 mth</div>
                </div>

                <div className={styles.check}>
                  {' '}
                  <input
                    type="radio"
                    name="looking"
                    value={'3-6'}
                    ref={register({ required: true })}
                  />
                  <div>3 - 6 mth</div>
                </div>

                <div className={styles.check}>
                  {' '}
                  <input
                    type="radio"
                    name="looking"
                    value={'6-12'}
                    ref={register({ required: true })}
                  />
                  <div>6 - 12 mth</div>
                </div>

                <div className={styles.check}>
                  {' '}
                  <input
                    type="radio"
                    name="looking"
                    value={'12'}
                    ref={register({ required: true })}
                  />
                  <div>12 mth +</div>
                </div>

                <div className={styles.errorMssg}>
                  {errors.looking && 'Time Period Required.'}
                </div>
              </div>
            )}
            {form.fields.includes('product') && (
              <div
                className={classnames(
                  styles.formgroup,
                  styles.formgroup__looking,
                )}
              >
                <label className={classnames(styles.looking__label)}>
                  What product are you looking for?
                </label>
                <div className={styles.check}>
                  <input
                    className={styles.product}
                    type="checkbox"
                    name="product"
                    value={'200-300'}
                    ref={register({ required: true })}
                  />
                  <div>200 - 300 sqm</div>
                </div>

                <div className={styles.check}>
                  <input
                    className={styles.product}
                    type="checkbox"
                    name="product"
                    value={'300-400'}
                    ref={register({ required: true })}
                  />
                  <div>300 - 400 sqm</div>
                </div>

                <div className={styles.check}>
                  <input
                    className={styles.product}
                    type="checkbox"
                    name="product"
                    value={'400-500'}
                    ref={register({ required: true })}
                  />
                  <div>400 - 500 sqm</div>
                </div>

                <div className={styles.check}>
                  <input
                    className={styles.product}
                    type="checkbox"
                    name="product"
                    value={'500-600'}
                    ref={register({ required: true })}
                  />
                  <div>500 - 600 sqm</div>
                </div>
                <div className={styles.check}>
                  <input
                    className={styles.product}
                    type="checkbox"
                    name="product"
                    value={'600-700'}
                    ref={register({ required: true })}
                  />
                  <div>600 - 700 sqm</div>
                </div>

                <div className={styles.errorMssg}>
                  {errors.product && 'Product Required.'}
                </div>
              </div>
            )}

            <div className={classnames(styles.formgroup, styles.terms)}>
              <input
                type="checkbox"
                id={`agreeTerms`}
                name="agreeTerms"
                className={classnames(styles.checkbox, {
                  [styles.errorField]: errors.agreeTerms,
                })}
                ref={register({ required: true })}
              />
              <label htmlFor={`agreeTerms`} className={styles.agreeTermslabel}>
                I have read and accepted the Privacy Policy and the Terms of
                Use.
                <div className={styles.errorMssg}>
                  {errors.agreeTerms &&
                    'You must accept the Privacy Policy and T&C.'}
                </div>
              </label>
            </div>

            <input
              type="hidden"
              name="elqFormName"
              value={eloqua.formName}
              ref={register()}
            />
            <input
              type="hidden"
              name="elqSiteID"
              value={eloqua.formID}
              ref={register()}
            />
            <input
              type="hidden"
              name="elqCustomerGUID"
              value=""
              ref={register()}
            />
            <input
              type="hidden"
              name="elqCookieWrite"
              value="0"
              ref={register()}
            />

            <Button
              submit
              className={styles.button}
              capitalized
              disabled={mailsent}
              size="md"
            >
              {mailsent === 'loading' ? 'Loading...' : form.buttonLabel}
            </Button>
          </div>

          <div
            className={classnames(styles.confirmation, {
              [styles.active]: mailsent,
            })}
          >
            <div className={classnames(styles.confirmation_content)}>
              <h3 className={styles.h3}>
                {thankyou.headlinePart1 && thankyou.headlinePart1}
                <br />
                {thankyou.headlinePart2 && (
                  <span>{thankyou.headlinePart2}</span>
                )}
              </h3>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: thankyou.paragraph }}
              ></div>
              <Button
                className={styles.button}
                capitalized
                size="md"
                onClick={newGuest}
              >
                {thankyou.buttonLabel}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Ipad;
