import React, { useContext, useState } from 'react';

import classnames from 'classnames';
import Icon from 'components/Icon';

import { ThemeContext } from 'theme/theme';

import { ReactComponent as Close } from './img/close.svg';

import styles from './FormModal.module.scss';

export const RefralModal = () => {
  const [copiedText, setCopiedText] = useState(false);

  const { refralModalForm, toggleRefralModalForm } = useContext(ThemeContext);

  const copy = document.querySelector('#copy');
  const copyText = document.querySelector('#copyText');

  if (copy) {
    copy.onclick = function() {
      document.execCommand('copy');
    };
    copy.addEventListener('copy', function(event) {
      event.preventDefault();
      if (event.clipboardData) {
        event.clipboardData.setData('text/plain', copyText.textContent);
        setCopiedText(true);
      }
    });
  }

  return (
    <>
      <div
        className={classnames(styles.modal, {
          [styles.active]: refralModalForm,
        })}
      >
        <div
          className={styles.modal_closearea}
          onClick={() => toggleRefralModalForm()}
        />
        <div
          className={classnames(styles.modal_content, {
            [styles.active]: refralModalForm,
          })}
        >
          <div className={styles.close} onClick={() => toggleRefralModalForm()}>
            <Close className={styles.icon} />
          </div>
          <div className={styles.refralModalWrapper}>
            <h3 className={styles.h3}>
              SPREAD THE WORD
              <span>TELL YOUR FRIENDS</span>
            </h3>
            <div className={styles.refralBox}>
              <h4>Refer a friend. Share the URL below.</h4>
              <div className={styles.refralBoxContent}>
                <div id="copyText" className={styles.inputBox}>
                  https://wiltongreens.com.au/tiktok
                </div>
                <div
                  id={!copiedText ? 'copy' : 'copied'}
                  className={classnames(styles.copy, {
                    [styles.copied]: copiedText,
                  })}
                >
                  {!copiedText ? 'Copy' : 'Copied'}
                </div>
              </div>
            </div>
            <ul className={styles.socialBox}>
              <li>
                <a
                  className={styles.icon}
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A//www.facebook.com/wiltongreensestate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="fb" className={styles.fb} />
                </a>
              </li>
              <li>
                <a
                  className={styles.icon}
                  href="https://www.instagram.com/wiltongreensestate/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="instagram" className={styles.insta} />
                </a>
              </li>
            </ul>
            <div className={styles.ticktokBox}>
              <h4>Follow us on TikTok</h4>
              <a
                href="https://www.tiktok.com/@wiltongreensestate"
                className={styles.tiktokWrap}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Icon name="tiktok" /> &nbsp; &nbsp; TikTok
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefralModal;
