import React from 'react';
import { Helmet } from 'react-helmet';
import PropertyApp, { PropertyAppProvider } from 'react-property-app';
// import HeadingPropertyApp from 'components/HeadingPropertyApp';

export const HouseAndLand = () => {
  return (
    <PropertyAppProvider>
      <Helmet>
        <title itemProp="name" lang="en">
          Wilton Greens | House and land
        </title>
      </Helmet>
      {/* <HeadingPropertyApp
        headingLarge={'<span class="bold">House</span> and Land'}
      /> */}
      <div className='houseAndLand'>
        <PropertyApp />
      </div>
    </PropertyAppProvider>
  );
};

export default HouseAndLand;
