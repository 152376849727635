import React, { useContext } from 'react';
import BuilderCardImg from 'static/images/bitmap.jpg';
import BedIcon from 'static/images/bed.svg';
import BathtubIcon from 'static/images/bathtub.svg';
import CarIcon from 'static/images/car.svg';
import VirtualTourIcon from 'static/images/virtual-tour.svg';
import styles from './HomeCard.module.scss';
import Button from 'components/Button';
import { ThemeContext } from 'theme/theme';

export const HomeCard = ({ builder, home }) => {
  const builderLogo = builder.builderFields?.logo?.mediaItemUrl;
  const homeFields = home.homeFields;
  const { toggleModalForm, setFormTypeRegister, setEnquiryData } = useContext(
    ThemeContext,
  );
  return (
    <div className={styles.card}>
      <div className={styles.iconContainer}>
        {builderLogo && (
          <div className={styles.iconOverlay}>
            <img src={builderLogo} alt="Icon" />
          </div>
        )}
        <a href={`/home/${home?.slug}`}>
          <img src={BuilderCardImg} alt="Home 1" className={styles.cardImage} />
        </a>
      </div>

      <div className={styles.cardContent}>
        <a href={`/home/${home?.slug}`} className={styles.cardTitle}>
          {home?.title}
        </a>
        {homeFields?.address && (
          <p className={styles.cardLocation}>{homeFields.address}</p>
        )}
        <div className={styles.cardIconsWrapper}>
          <hr className={styles.divider} />
          <div className={styles.cardIcons}>
            {homeFields?.bed && (
              <div className={styles.icon}>
                <span className={styles.numbers}>{homeFields.bed}</span>
                <img src={BedIcon} alt="bed icon" />
              </div>
            )}
            {homeFields?.bathroom && (
              <div className={styles.icon}>
                <span className={styles.numbers}>{homeFields.bathroom}</span>
                <img src={BathtubIcon} alt="bath icon" />
              </div>
            )}
            {homeFields?.garage && (
              <div className={styles.icon}>
                <span className={styles.numbers}>{homeFields.garage}</span>
                <img src={CarIcon} alt="car icon" />
              </div>
            )}
          </div>
          <hr className={styles.divider} />
        </div>

        <div className={styles.cardButtons}>
          {homeFields?.threeSixtyView && (
            <div className={styles.tourBtn}>
              <img
                className={styles.icon}
                src={VirtualTourIcon}
                alt="virtual tour icon"
              />
              <Button
                className={styles.btnText}
                variant="green"
                link={homeFields?.virtualTour}
                target="_blank"
              >
                TAKE THE VIRTUAL TOUR
              </Button>
            </div>
          )}
          <div className={styles.downloadBtn}>
            <Button
              className={styles.downloadBtnText}
              variant="primary"
              link={homeFields?.homeFlyer}
              target="_blank"
            >
              DOWNLOAD FLYER
            </Button>
            <Button
              className={styles.downloadBtnText}
              variant="primary"
              onClick={() => {
                toggleModalForm(true);
                setFormTypeRegister();
                setEnquiryData(home.title, builder.title);
              }}
            >
              ENQUIRE NOW
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
