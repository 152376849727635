import React, { useState } from "react";
// import PropTypes from "prop-types";
import classnames from "classnames";
import { Link } from "react-router-dom";

// import config from "traffic-property-app/config";

// // Components
// import Icon from "traffic-property-app/components/Icon";
//
import styles from "./CoordMap.module.scss";
import "./CoordMap.scss";

export const CoordMap = ({ levels, active, noButtons, onClick }) => {
  
  const [activeLevel, setActiveLevel] = useState(0);
  const level = levels[activeLevel];
  const { coords = [], image = {}, link, activePolygon, legend = [] } =
    level || {};

  const polygons = coords.map(coord => {
    // if (typeof coord === "object") return coord.coord;
    return coord;
  });

  const zoomOut = () => {
    let newLevel = activeLevel + 1;
    setActiveLevel(
      newLevel >= levels.length - 1 ? levels.length - 1 : newLevel
    );
  };
  const zoomIn = polygon => {
    let newLevel = activeLevel - 1;
    setActiveLevel(newLevel <= 0 ? 0 : newLevel);
    if (typeof onClick === "function") onClick(polygon);
  };

  const width = image.width || 1440;
  const height = image.height || 972;

  noButtons =
    noButtons || (activeLevel === 0 && activeLevel === levels.length - 1);

  return (
    <div className={styles.coordMap}>
      {!noButtons ? (
        <div className={styles.zoom}>
          <button onClick={zoomIn} disabled={activeLevel === 0}>
            +
          </button>
          <button
            onClick={zoomOut}
            disabled={activeLevel === levels.length - 1}
          >
            -
          </button>
        </div>
      ) : null}

      {levels.map((level, i) => {
        const image = level.image && level.image.url;
        if (!image) return null;
        return (
          <img
            src={image}
            className={classnames(
              "levelimg",
              { active: i === activeLevel },
              { parent: i === 0 }
            )}
            title="Plan"
            width="100%"
            alt="plan"
            key={level + i}
          />
        );
      })}

      {legend.length ? (
        <div className="legend">
          {legend.map(({ label, className }, i) => (
            <span
              key={`legend-${i}`}
              className={`legend__${className || label.toLowerCase()}`}
            >
              {label}
            </span>
          ))}
        </div>
      ) : null}

      {polygons.length ? (
        <svg viewBox={`0,0,${width},${height}`}>
          {polygons.map((polygon, i) => {
            const { name, coords } = polygon;
            const status = polygon.status_id || polygon.status;
            const href =
              typeof link === "function" ? link(polygon) : link || "";

            const className = classnames({
              [styles.active]:
                active === polygon.slug || activePolygon === polygon.slug
            });

            // const [x, y] = midpoint.split(",");

            // @TODO CANNOT FIND STATUS
            const Polygon = props => {
              if (href)
                return (
                  <Link to={href} className={className}>
                    <polygon {...props} />
                  </Link>
                );

              return <polygon {...props} onClick={() => zoomIn(polygon)} />;
            };

            if (status === 1) {
              return <Polygon key={href + name} points={coords} alt={name} />;
            }

            if (status === 2) {
              return (
                <Polygon
                  key={href + name}
                  points={coords}
                  alt={name}
                  className={styles.sold}
                />
              );
            }

            return (
              <Polygon
                key={href + name}
                points={coords}
                alt={name}
                className={styles.builder}
              />
            );
          })}
        </svg>
      ) : null}
    </div>
  );
};

// const coordMapLevels = [
// {
//   title: "Floor Plates",
//   navigation: [],
//   coords: [],
//   image: ''
// }
// ];

CoordMap.propTypes = {
  // size: PropTypes.oneOf(["small", "medium", "large"]),
  // color: PropTypes.oneOf(["primary", "secondary"]),
  // icon: PropTypes.string,
  // href: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // tag: PropTypes.string
};

export default CoordMap;
