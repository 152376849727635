export function extractVideoId(url) {
    const youtubePattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&?/\s]{11})/;
    const vimeoPattern = /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/;

    // Check if the URL matches YouTube
    const youtubeMatch = url.match(youtubePattern);
    if (youtubeMatch) {
        return { platform: "YouTube", videoId: youtubeMatch[1] };
    }

    // Check if the URL matches Vimeo
    const vimeoMatch = url.match(vimeoPattern);
    if (vimeoMatch) {
        return { platform: "Vimeo", videoId: vimeoMatch[1] };
    }

    return { platform: null, videoId: null };
}