import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { formatDate } from 'utils/formatDate';
import { stringSlice } from 'utils/stringSlice';

import styles from './Listings.module.scss';

export const Listing = ({ post }) => {
  const excerpt = stringSlice(post.excerpt, 150);
  return (
    <Link to={`/news-and-events/${post.slug}`} className={styles.listing}>
      <div className={styles.holder}>
        <div
          className={styles.image}
          style={
            post.featuredImage
              ? { backgroundImage: `url(${post.featuredImage.mediaItemUrl})` }
              : null
          }
        />

        <div className={styles.catDate}>
          <span className={styles.cat}>{post.categories.nodes[0].name}</span>
          <span className={styles.date}>{formatDate(post.date)}</span>
        </div>
      </div>
      <div className={styles.info}>
        <h3 className={styles.title}>{stringSlice(post.title, 75)}</h3>
        <p
          className={classnames(styles.excerpt, 'font-copy-sm')}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></p>
      </div>
    </Link>
  );
};

export default Listing;
