import React from "react";
import { useHistory } from 'react-router-dom';
import BackArrow from "static/images/backArrow.svg";
import styles from "./backbutton.module.scss";

export default function BackButton({ title, url }) {
    const history = useHistory();
    return (
        <div onClick={() => history.push(url)} className={styles.bttnWrapper}>
            <img src={BackArrow} alt="back arrow"/>
            <span>{title}</span>
        </div>
    )
}