import gql from 'graphql-tag';

export const BUILDERS_QUERY = gql`
  query BuilderQuery {
    builders(first: 100) {
      edges {
        node {
          id
          builderFields {
            isListed
            logo {
              mediaItemUrl
              altText
            }
            status
            threeSixtyView
          }
          slug
          title
        }
      }
    }
  }
`;

export const HOMES_QUERY = gql`
  query HomesQuery {
    homes(first: 100) {
      edges {
        node {
          id
          homeFields {
            bed
            bathroom
            garage
            storeys
            builder {
              ... on Builder {
                id
                slug
              }
            }
          }
        }
      }
    }
  }
`;
