import React from "react"

import styles from "./IntroWide.module.scss";

export const IntroWide = ({ ...props }) => {
  const {
    introParagraph,
    paragraph
  } = props;

  return (
    <section className={styles.introWide}>
      <div className="container">
        <h4 className={styles.h4}>{ introParagraph }</h4>
        <p className={styles.paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />
      </div>
    </section>
  )
}

export default IntroWide