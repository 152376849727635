import { useEffect } from "react";

export const ScrollToTop = ({...props}) => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]); // eslint-disable-line

  return null;
};

export default ScrollToTop;
