import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Icon from 'components/Icon';

import { ThemeContext } from 'theme/theme';

import styles from './Topbar.module.scss';

export const Topbar = ({ data }) => {
  const { editableRegionLeft, editableRegionRight } = data;
  const { toggleModalForm, setFormTypeDownload } = useContext(ThemeContext);

  const showForm = () => {
    setFormTypeDownload();
    toggleModalForm();
  };

  return (
    <div className={styles.topbar}>
      <div className={`container`}>
        <div className={classnames(styles.regionLeft, styles.regions)}>
          {editableRegionLeft.linkType === 'form' ? (
            <div onClick={showForm}>
              {editableRegionLeft.text}
              <Icon name="arrow" className={styles.arrow} />
            </div>
          ) : editableRegionLeft.newWindow ? (
            <a
              href={editableRegionLeft.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {editableRegionLeft.text}
              <Icon name="arrow" className={styles.arrow} />
            </a>
          ) : (
            <Link to={editableRegionLeft.link}>
              {editableRegionLeft.text}
              <Icon name="arrow" className={styles.arrow} />
            </Link>
          )}
        </div>
        <div className={classnames(styles.regionRight, styles.regions)}>
          {editableRegionRight.newWindow ? (
            <>
              <a href="tel:133188" className={classnames([styles.phone, styles.phoneTop])}>
                Call us on 133 188
                <Icon name="phoneWhite" className={styles.phoneicon} />
              </a>
              <a
                href={editableRegionRight.link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.topbarMarker}
              >
                {editableRegionRight.text}
                <Icon name="marker" className={styles.marker} />
              </a>
              <span className={styles.socialWrapper}>
                <a className={styles.instaTop} href="https://www.instagram.com/wiltongreensestate/" target="_blank" rel="noopener noreferrer">
                  <Icon name="instagram" className={styles.instaicon} />
                </a>
                <a className={styles.fbTop} href="https://www.facebook.com/wiltongreensestate" target="_blank" rel="noopener noreferrer">
                  <Icon name="fb" className={styles.fbicon} />
                </a>
                <a className={styles.tiktokTop} href="https://www.tiktok.com/@wiltongreensestate?is_from_webapp=1&sender_device=pc" target="_blank" rel="noopener noreferrer">
                  <Icon name="tiktok" className={styles.tiktokicon} />
                </a>
              </span>
            </>
          ) : (
            <Link to={editableRegionRight.link}>
              {editableRegionRight.text}
              <Icon name="marker" className={styles.marker} />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
