import gql from 'graphql-tag';

// GraphQL page query that takes a page slug as a uri
// Returns the title and content of the page

const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      title
      uri
      slug
      title
      pageFields {
        enableLandingHeader
      }
      viewPageButton {
        pageLinkText
        showViewPageButton
        colour
        viewPageLink {
          ... on Page {
            link
            slug
          }
        }
      }
      acf {
        blocks {
          ... on Page_Acf_Blocks_HeroSliderEoi {
            fieldGroupName
            headline
            subheadlinePart1
            subheadlinePart2
            buttonLabel
            buttonTriggerForm
            buttonLink
            shapeColour
            showIllustration
            illustration {
              sourceUrl(size: LARGE)
            }
            backgroundImageSlides {
              sourceUrl(size: _2048X2048)
            }
          }

          ... on Page_Acf_Blocks_HeroImage {
            fieldGroupName
            headlinePart1
            headlinePart2
            illustration {
              mediaItemUrl
            }
            image {
              mediaItemUrl
            }
            layoutOptions
            marginBottom
            shapeColour
            subheadline
            illustrationMarginRight
          }

          ... on Page_Acf_Blocks_DisplayVillageHero {
            fieldGroupName
            headlinePart1
            headlinePart2
            illustration {
              mediaItemUrl
            }
            image {
              mediaItemUrl
            }
            layoutOptions
            marginBottom
            shapeColour
            subheadline
            illustrationMarginRight
            details
            subDetails
            openingHours
            buttons {
              title
              url
            }
          }

          ... on Page_Acf_Blocks_HeroCounter {
            fieldGroupName
            headlinePart1
            headlinePart2
            headlinePart3
            countdownTimerEndDate
            image {
              mediaItemUrl
            }
            marginBottom
            shapeColour
            subheadline
          }

          ... on Page_Acf_Blocks_TiktokCampaignLandingContent {
            fieldGroupName
            heading
            details
            pageIllustrator {
              mediaItemUrl
            }
            refral {
              text
              icon {
                mediaItemUrl
              }
            }
            tiktok {
              text
              url
              icon {
                mediaItemUrl
              }
            }
            termsAndConditions
            button {
              title
              url
            }
            facebookLink
            instagramLink
            formid
          }

          ... on Page_Acf_Blocks_IntroWide {
            fieldGroupName
            introParagraph
            paragraph
          }

          ... on Page_Acf_Blocks_IntroNarrow {
            addVideoLink
            fieldGroupName
            videoLink
            videoButtonText
            headingPart1
            headingPart2
            introParagraph
            paragraph
            videoScreen {
              mediaItemUrl
            }
          }

          ... on Page_Acf_Blocks_ArticleFluidImage {
            fieldGroupName
            zIndex
            margins {
              top
              bottom
            }
            illustration {
              sourceUrl(size: LARGE)
            }
            slide {
              headlinePart1
              headlinePart1Bold
              headlinePart2
              headlinePart2Bold
              paragraph
              fluidImage {
                sourceUrl(size: LARGE)
              }
              icon {
                sourceUrl(size: LARGE)
              }
            }
          }

          ... on Page_Acf_Blocks_ArticleSummary {
            fieldGroupName
            headingPart1
            headingPart2
            paragraph
            zIndex
            image {
              mediaItemUrl
            }
            item {
              paragraph
              title
              list {
                listItem
              }
            }
          }

          ... on Page_Acf_Blocks_ArticleSingleFullWidth {
            fieldGroupName
            headingPart1
            headingPart2
            paragraph
            zIndex
            link {
              target
              title
              url
            }
          }

          ... on Page_Acf_Blocks_ArticleSingleColumn {
            fieldGroupName
            headingPart1
            headingPart2
            paragraph
            waveTopFillColour
            zIndex
          }

          ... on Page_Acf_Blocks_ArticleTwoColumns {
            fieldGroupName
            zIndex
            articles {
              headline
              paragraph
              image {
                sourceUrl(size: LARGE)
              }
              isIcon
            }
            image {
              sourceUrl(size: LARGE)
            }
          }

          ... on Page_Acf_Blocks_ArticleThreeColumns {
            fieldGroupName
            headlinePart1
            headlinePart1Bold
            headlinePart2
            headlinePart2Bold
            paragraph
            shapeColour
            zIndex
            slide {
              headline
              image {
                sourceUrl(size: LARGE)
              }
              text
            }
            downloadCopy
            downloadForm
            illustration {
              mediaItemUrl
            }
          }

          ... on Page_Acf_Blocks_Image {
            fieldGroupName
            size
            zIndex
            imagePosition
            button {
              buttonLink
              buttonTextPart1
              buttonTextPart2
            }
            illustration {
              sourceUrl(size: LARGE)
            }
            mainImage {
              mediaItemUrl
            }
          }

          ... on Page_Acf_Blocks_BulletBlock {
            backgroundColour
            fieldGroupName
            paragraph
            zIndex
            illustration {
              mediaItemUrl
            }
            points {
              item
            }
          }

          ... on Page_Acf_Blocks_VideoPopUpModal {
            fieldGroupName
            headlinePart1
            headlinePart2
            paragraph
            playButtonText
            zIndex
            videoLink
            videoScreenShot {
              mediaItemUrl
            }
            icon {
              mediaItemUrl
            }
          }

          ... on Page_Acf_Blocks_IllustrationMap {
            fieldGroupName
          }

          ... on Page_Acf_Blocks_Statistics {
            fieldGroupName
            note
            zIndex
            statistic {
              heading
              paragraph
              stat
            }
          }

          ... on Page_Acf_Blocks_Masterplan {
            description
            fieldGroupName
            locations {
              colour
              name
              coordinates {
                mobileAdjustments
                x
                xMobile
                y
                yMobile
              }
            }
            mapDownload {
              mediaItemUrl
            }
            subTitle
            title
            mapImage {
              mediaItemUrl
            }
          }
          ... on Page_Acf_Blocks_Amenities {
            fieldGroupName
            amenitiesDescription
            amenitiesTitle
            locationDescription
            locationGoogleMapUrl
            locationHeading
            locationSubHeading
            locationTitle
            amenitiesCategories {
              amenitiesCategoryColour
              amenitiesCategoryName
              amenities {
                amenityName
                coordinates {
                  x
                  y
                  mobileAdjustments
                  xMobile
                  yMobile
                }
              }
            }
            amenitiesMapImage {
              mediaItemUrl
            }
            locationMap {
              mediaItemUrl
            }
          }

          ... on Page_Acf_Blocks_Contact {
            fieldGroupName
            headingPart1
            headingPart2
            paragraph
            additionalInformation1
            additionalInformation2
            additionalInformation3
            directionsLink {
              target
              title
              url
            }
          }
          ... on Page_Acf_Blocks_MasterplanImage{
            fieldGroupName
            image {
              mediaItemUrl
              altText
            }
          }
          ... on Page_Acf_Blocks_Builders{
            fieldGroupName
            heading
          }
          ... on Page_Acf_Blocks_TextLeftImageRight {
            fieldGroupName
            detailsOne
            detailsTwo
            title
            button {
              title
              url
            }
            image {
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export default PAGE_QUERY;
