import React from 'react';
import classnames from 'classnames';

import { ReactComponent as WaveBottom } from 'static/images/layout/wave-bottom-900.svg';
import { ReactComponent as Leaf } from 'static/images/pink-leaf.svg';

import styles from './ArticleSummary.module.scss';

export const ArticleSummary = props => {
  const { zIndex, headingPart1, headingPart2, paragraph, image, item } = props;
  return (
    <section
      className={styles.articlesummary}
      style={{ zIndex: zIndex ? zIndex : null }}
    >
      <WaveBottom className={styles.waveTop} />
      <div className={classnames('container')}>
        <div className={styles.left}>
          <h2 className={styles.headline}>
            {headingPart1}
            <span>{headingPart2}</span>
          </h2>
          <p className={styles.paragraph}>{paragraph}</p>
          {image ? (
            <img
              src={image.mediaItemUrl}
              alt="students"
              className={classnames(styles.students, styles.desktop)}
            />
          ) : null}
        </div>
        <div className={styles.right}>
          {item.map((things, i) => {
            // console.log(things);
            return (
              <div key={`summary-${i}`} className={styles.item}>
                <h3 className={styles.title}>{things.title}</h3>
                <p dangerouslySetInnerHTML={{ __html: things.paragraph }} />

                <ul>
                  {things.list.map((thing, i) => {
                    return (
                      <li
                        key={`thing-${i}`}
                        dangerouslySetInnerHTML={{ __html: thing.listItem }}
                      />
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>

        {image ? (
          <img
            src={image.mediaItemUrl}
            alt="students"
            className={classnames(styles.students, styles.mobile)}
          />
        ) : null}
      </div>
      <Leaf className={styles.leaf} />
      <WaveBottom className={styles.waveBottom} />
    </section>
  );
};

export default ArticleSummary;
