import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import VirtualIcon from 'static/images/virtual-tour.svg';
import FilterToggleIcon from 'static/icons/fiter-toggle.svg';
import BuilderCard from 'components/BuilderCard';
import { BUILDERS_QUERY, HOMES_QUERY } from 'graphqlQueries/buildersQuery';
import FilterItem from 'components/FilterItem';
import styles from './Builders.module.scss';

export const Builders = ({ client, heading }) => {
  const [builders, setBuilders] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [filter, setFilter] = useState({
    bathrooms: 'all',
    bedrooms: 'all',
    garage: 'all',
    storeys: 'all',
    specialOffer: 'all',
  });

  useEffect(() => {
    (async () => {
      const homesResult = await client.query({
        query: HOMES_QUERY,
      });
      const result = await client.query({
        query: BUILDERS_QUERY,
      });

      const homes = homesResult?.data?.homes?.edges || [];
      let buildersLists = result?.data?.builders?.edges.filter(
        item => item?.node?.builderFields?.isListed,
      );

      // Filter builders based on homes data
      buildersLists = buildersLists.filter(builder => {
        const builderHomes = homes.filter(
          home => home.node.homeFields.builder.id === builder.node.id
        );

        return builderHomes.some(home => {
          const homeFields = home.node.homeFields;
          return (
            (filter.bedrooms === 'all' || homeFields.bed === filter.bedrooms) &&
            (filter.bathrooms === 'all' || homeFields.bathroom === filter.bathrooms) &&
            (filter.garage === 'all' || homeFields.garage === filter.garage) &&
            (filter.storeys === 'all' || (homeFields.storeys && homeFields.storeys === filter.storeys))
          );
        });
      });

      if (filter.specialOffer === 'yes') {
        buildersLists = buildersLists.filter(
          item =>
            item?.node?.builderFields?.status?.toLowerCase() ===
            'special offer',
        );
      }

      setBuilders(buildersLists);
    })();
  }, [client, filter]);

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.titleSection}>
          {heading && <h2 dangerouslySetInnerHTML={{ __html: heading }} />}
          <div className={styles.rightColumn}>
            <img className={styles.virtualIcon} src={VirtualIcon} alt="icon" />
            <p className={styles.virtualIconText}>
              Experience a 3600
              <span className={styles.degreeSymbol}>&#176;</span> <br />
              Virtual Tour
            </p>
          </div>
        </div>
        <div className={styles.filterWrapper}>
          <div className={styles.filterTopBar}>
            <div className={styles.filterTopBarLeft}>
              <h2>Refine your search</h2>
              <span
                onClick={() => setShowFilters(!showFilters)}
                className={styles.filterToggle}
              >
                <img src={FilterToggleIcon} alt="Filter toggle" />
              </span>
            </div>
            <div className={styles.reset}>Reset</div>
          </div>
          <h3>property type</h3>

          <div
            style={showFilters ? { display: 'flex' } : null}
            className={styles.filters}
          >
            <FilterItem
              filter={filter}
              setFilter={setFilter}
              filterName="bedrooms"
              title="Bedrooms"
              data={['1', '2', '3', '4', '5+']}
            />
            <FilterItem
              filter={filter}
              setFilter={setFilter}
              filterName="bathrooms"
              title="Bathrooms"
              data={['1', '1.5', '2', '2.5', '3+']}
            />
            <FilterItem
              filter={filter}
              setFilter={setFilter}
              filterName="garage"
              title="Car Spaces"
              data={['1', '2']}
            />
            <FilterItem
              filter={filter}
              setFilter={setFilter}
              filterName="storeys"
              title="Storeys"
              data={['1', '2']}
            />
            <FilterItem
              filter={filter}
              setFilter={setFilter}
              filterName="specialOffer"
              title="Special Offers"
              data={[]}
            />
          </div>
        </div>
        <div className={styles.gridContainer}>
          {builders?.map((builder, index) => (
            <BuilderCard builder={builder?.node} key={`builder-${index}`} />
          ))}
          {builders?.length === 0 && <p>Builders Not Available</p>}
        </div>
      </div>
    </section>
  );
};

export default withApollo(Builders);
