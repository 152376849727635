import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { Helmet } from 'react-helmet';
import gql from 'graphql-tag';

import classnames from 'classnames';
import Listings from 'components/Listings';
import Categories from 'components/Categories';
import ScrollToTop from 'utils/ScrollToTop';

import styles from './newsandevents.module.scss';
/**
 * GraphQL post search query that takes a filter
 * Returns the titles, slugs and authors of posts found
 */
const POST_SEARCH_QUERY = gql`
  query PostSearchQuery($filter: String!) {
    posts(where: { search: $filter }) {
      edges {
        node {
          excerpt
          title
          modified
          link
          slug
          featuredImage {
            mediaItemUrl
          }
          categories {
            nodes {
              name
              slug
              categoryId
            }
          }
          id
        }
      }
    }
    categories {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

/**
 * Search component that fetches results by filter
 */
export const NewsandeventsSearch = props => {
  const [state, setState] = useState({
    categories: null,
    posts: null,
  });

  /**
   * Execute search query, process the response and set the state
   */
  useEffect(() => {
    let requestCancelled = false;

    const executeSearch = async () => {
      const { match, client } = props;
      const filter = match.params.slug;
      let posts = [];
      if (filter.length === 0) {
        setState({ posts });
      } else {
        const result = await client.query({
          query: POST_SEARCH_QUERY,
          variables: { filter },
        });
        if (!requestCancelled) {
          const categories = result.data.categories;
          const posts = result.data.posts.edges;
          setState({ categories, posts });
        }
      }
    };

    executeSearch();

    return () => {
      requestCancelled = true;
    };
  }, [props]);

  const { posts, categories } = state;

  if (!posts) {
    return null;
  } else if (posts && posts.length < 1) {
    return (
      <section className={classnames('container', styles.newsandevents)}>
        <ScrollToTop />
        <Helmet>
          <title itemProp="name" lang="en">
            Wilton Greens | News and Events
          </title>
        </Helmet>
        <div className={styles.headingSmall}>
          <h2 className={'font-base-sm'}>Community News</h2>
        </div>
        <Categories
          heading={'<span class="bold">News</span> and Events'}
          categories={categories}
        />

        <div className={styles.headingSmall}>
          <h4 className={'headings-font-display-4'}><span className={'bold'}>No Results</span> Found</h4>
        </div>
      </section>
    );
  } else {
    return (
      <section className={classnames('container', styles.newsandevents)}>
        <ScrollToTop />
        <Helmet>
          <title itemProp="name" lang="en">
            Wilton Greens | News and Events
          </title>
        </Helmet>
        <div className={styles.headingSmall}>
          <h2 className={'font-base-sm'}>Community News</h2>
        </div>
        <Categories
          heading={'<span class="bold">News</span> and Events'}
          categories={categories}
        />
        <Listings posts={posts} />
      </section>
    );
  }
};

export default withApollo(NewsandeventsSearch);
