import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import Slider from 'components/Slider';
import BackButton from 'components/BackButton';
import Button from 'components/Button';
import BedIcon from 'static/images/bed.svg';
import BathTubIcon from 'static/images/bathtub.svg';
import CarIcon from 'static/images/car.svg';
import styles from './home.module.scss';
import { ThemeContext } from 'theme/theme';

const HOME_QUERY = gql`
  query HomeQuery($url: String!) {
    homeBy(slug: $url) {
      title
      homeFields {
        address
        bathroom
        bed
        features {
          feature
        }
        firstFloorArea
        garage
        groundFloorArea
        height
        lotNo
        lotWidth
        threeSixtyView
        totalArea
        width
        status
        details
        homeFlyer
        virtualTour
        slider {
          type
          image {
            altText
            mediaItemUrl
          }
          video
        }
        builder {
          ... on Builder {
            title
            slug
          }
        }
      }
    }
  }
`;

export const Home = props => {
  const {
    toggleModalForm,
    setFormTypeRegister,
    setEnquiryData,
  } = useContext(ThemeContext);
  const [homeDetails, setHomeDetails] = useState();
  const history = useHistory();
  const { client, match } = props;
  const url = match?.params?.[0];
  useEffect(() => {
    (async () => {
      const result = await client.query({
        query: HOME_QUERY,
        variables: { url },
      });
      const homeFields = result?.data;
      setHomeDetails(homeFields);
    })();
  }, [client, url]);
  const homeFields = homeDetails?.homeBy?.homeFields;
  const features = homeFields?.features;
  const builder = homeFields?.builder ? homeFields.builder : null;
  const sliderData = homeFields?.slider;
  return (
    <div className={styles.wrapper}>
      <div className={styles.mobileBackBttn}>
        <div className={styles.container}>
          {builder && (
            <BackButton
              url={`/builder/${builder?.slug}`}
              title={`Back to ${builder?.title}`}
            />
          )}
        </div>
      </div>
      <div className={styles.mobileSlider}>
        <Slider data={sliderData} />
      </div>
      <div className={styles.container}>
        <div className={styles.desktopBackBttn}>
          {builder && (
            <BackButton
              url={`/builder/${builder?.slug}`}
              title={`Back to ${builder?.title}`}
            />
          )}
        </div>
        <div className={styles.textAndImageBlock}>
          <div className={styles.textWrap}>
            <h2>
              {homeFields?.lotNo}
              {homeDetails?.homeBy?.title && (
                <strong>{homeDetails.homeBy.title}</strong>
              )}
            </h2>
            {homeFields?.details && (
              <p className={styles.description}>{homeFields.details}</p>
            )}
            {(homeFields?.bed ||
              homeFields?.bathroom ||
              homeFields?.garage) && (
              <div className={styles.iconsWrapper}>
                <hr className={styles.divider} />

                <div className={styles.icons}>
                  {homeFields?.bed && (
                    <div className={styles.icon}>
                      <span className={styles.number}>{homeFields?.bed}</span>
                      <img src={BedIcon} alt="bed icon" />
                    </div>
                  )}
                  {homeFields?.bathroom && (
                    <div className={styles.icon}>
                      <span className={styles.number}>
                        {homeFields?.bathroom}
                      </span>
                      <img src={BathTubIcon} alt="bath tub icon" />
                    </div>
                  )}
                  {homeFields?.garage && (
                    <div className={styles.icon}>
                      <span className={styles.number}>
                        {homeFields?.garage}
                      </span>
                      <img src={CarIcon} alt="car icon" />
                    </div>
                  )}
                </div>

                <hr className={styles.divider} />
              </div>
            )}
            <div className={styles.propertyDetails}>
              <div className={styles.dimensions}>
                {homeFields?.lotWidth && (
                  <p className={styles.dimensionTitle}>
                    <strong>LOT WIDTH*</strong> {homeFields?.lotWidth}
                  </p>
                )}
                {homeFields?.totalArea && (
                  <p>
                    Total Area <strong>{homeFields?.totalArea}</strong>
                  </p>
                )}
                {homeFields?.groundFloorArea && (
                  <p>
                    Ground Floor Area{' '}
                    <strong>{homeFields?.groundFloorArea}</strong>
                  </p>
                )}
                {homeFields?.firstFloorArea && (
                  <p>
                    First Floor Area{' '}
                    <strong>{homeFields?.firstFloorArea}</strong>
                  </p>
                )}
                {homeFields?.width && (
                  <p>
                    Width <strong>{homeFields?.width}</strong>
                  </p>
                )}
                {homeFields?.height && (
                  <p>
                    Length <strong>{homeFields?.height}</strong>
                  </p>
                )}
              </div>
              {features && features?.length > 0 && (
                <div className={styles.features}>
                  <p className={styles.featuresTitle}>
                    <strong>FEATURES:</strong>
                  </p>
                  <ul>
                    {/* {homeFields?.features?.map((item, index) => (<li key={`feature-${index}`}>{item}</li>))} */}
                    {features?.map((item, index) => (
                      <li key={`feature-${index}`}>{item.feature}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className={styles.buttons}>
              <Button
                onClick={() => {
                  toggleModalForm(true);
                  setFormTypeRegister();
                  setEnquiryData(homeDetails.homeBy.title, builder.title);
                }}
                className={styles.bttn}
                variant="default"
              >
                ENQUIRE ABOUT THIS PACKAGE
              </Button>
              {homeFields?.homeFlyer && (
                <Button
                  target="_blank"
                  className={styles.bttn}
                  variant="outline"
                  link={homeFields.homeFlyer}
                >
                  DOWNLOAD HOME FLYER
                </Button>
              )}
              {homeFields?.virtualTour && (
                <Button
                  target="_blank"
                  className={styles.bttn}
                  variant="green"
                  link={homeFields.virtualTour}
                >
                  Experience a 360 Virtual Tour
                </Button>
              )}
              <Button className={styles.bttn} variant="default" link="#">
                EXPLORE HOUSE & LAND PACKAGES
              </Button>
              {homeFields?.status?.toLowerCase() === 'special offer' && (
                <Button
                  className={styles.bttn}
                  variant="basic"
                  // link="/special-offer"
                  onClick={() =>
                    history.push(`/special-offer`, {
                      builder: builder?.title,
                      slug: `/builder/${builder?.slug}`,
                    })
                  }
                >
                  SPECIAL OFFER
                </Button>
              )}
            </div>
          </div>
          <div className={styles.desktopSlider}>
            <Slider data={sliderData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withApollo(Home);
