import React from 'react';
import classnames from 'classnames';
import styles from './BulletBlock.module.scss';

import { ReactComponent as WaveTopBullet } from 'static/images/layout/wave-top-bullet-block.svg';

export const BulletBlock = props => {
  const { backgroundColour, illustration, paragraph, points, zIndex } = props
    ? props
    : null;
  return (
    <>
      <WaveTopBullet className={styles.waveTopBullet} fill={backgroundColour} />
      <section
        className={styles.bulletBlock}
        style={{
          backgroundColor: backgroundColour,
          zIndex: zIndex ? zIndex : null,
        }}
      >
        <div className={classnames(styles.container, 'container')}>
          <div className={styles.paragraph}>
            <p className={'font-base-md'}>{paragraph}</p>
          </div>
          <div className={styles.bullets}>
            <ul>
              {points.map((point, i) => {
                return (
                  <li
                    key={`bullet-block-points-${i}`}
                    dangerouslySetInnerHTML={{ __html: point.item }}
                  />
                );
              })}
            </ul>
          </div>
        </div>
        <div className={styles.illustration}>
          <img src={illustration.mediaItemUrl} alt="" />
        </div>
      </section>
    </>
  );
};

export default BulletBlock;
