import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import { createHttpLink } from 'apollo-link-http';
import introspectionQueryResultData from './fragmentTypes.json';
import Config from './config';
import * as serviceWorker from "./serviceWorker";
import App from './App';

import "typeface-lato";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

// Apollo GraphQL client
const client = new ApolloClient({
  link: createHttpLink({
    uri: Config.gqlUrl,
  }),
  cache: new InMemoryCache({fragmentMatcher}),
});


ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);

serviceWorker.unregister();