window.traffic = {
  ...(window.traffic || {}),
  //
  // Configure API
  //
  api: {
    url: "https://interactive-wilton.ts.r.appspot.com/api/interactive",
    // url: "https://interactive-wilton.ts.r.appspot.com/api/staging/interactive",
    neighbourhood: 'stage-1',
  },

  status: {
    1: "Available",
    2: "Sold",
    3: "House & Land Exclusive",
    4: "Deposit Taken",
    5: "Coming Soon",
  },
  
  other_options: {
    masterplan: {
      show_price_next_to_lot: true,
      legend: [
        { label: "Coming Soon" },
        { label: "Available" },
        { label: "Deposit Taken" },
        { label: "House & Land Exclusive" },
        { label: "Sold" }
    ]
    },
  },

};

if (process.env.NODE_ENV === 'development') {
  window.traffic = {
    //
    // Configure API
    //
    api: {
      url: "https://interactive-wilton.ts.r.appspot.com/api/staging/interactive",
      // url: "/payload.json",
      neighbourhood: 'stage-1',
    },
    
    status: {
      1: "Available",
      2: "Sold",
      3: "House & Land Exclusive",
      4: "Deposit Taken",
      5: "Coming Soon",
    },

    other_options: {
      masterplan: {
        show_price_next_to_lot: true,
        legend: [
          { label: "Coming Soon" },
          { label: "Available" },
          { label: "Deposit Taken" },
          { label: "House & Land Exclusive" },
          { label: "Sold" }
        ]
      },
    },

    //
    // Configure Advertising
    //
    promotions: [],

    //
    // Confire routes
    //
    routes: {
      houseandland: 'house-and-land',
      townhomes: 'townhomes',
      landforsale: {
        masterplan: 'land-for-sale',
        results: 'land-for-sale-results',
      },
      property: {
        search: 'property-search',
        results: 'house-and-land',
      },
    },

    //
    // Confire filters labels
    //
    labels: {
      filters: {
        neighbourhood: 'precincts',
      },
    },

    filters: {
      parking: { from: 1, to: 2 },
      stories: { from: 1, to: 2 },
    },

    //
    // Configure Styling
    //
    // style: {
    // },

    //
    // Enquiry Form
    //
    enquiry_form: {
      tfa_id: '4739461',
      // tfa_ids: '4739461'
    },

    //
    // Downloads
    //
    // downloads: {
    // },
  };
}
