import React, { useContext, useEffect, useState } from 'react';
import Button from 'components/Button';
import CountdownTimer from 'components/CountdownTimer';
import classnames from 'classnames';
import { scroller } from 'react-scroll';
import useWindowSize from '@react-hook/window-size';
import { ReactComponent as Wave } from 'static/images/layout/wave-bottom-900.svg';
import { ReactComponent as WaveMobile } from 'static/images/layout/wave-bottom-mobile.svg';
import styles from './HeroCounter.module.scss';
import { ThemeContext } from 'theme/theme';

export const HeroCounter = ({ ...props }) => {
  const {
    headlinePart1,
    headlinePart2,
    headlinePart3,
    countdownTimerEndDate,
    subheadline,
    shapeColour,
    image,
  } = props;

  const [width] = useWindowSize();
  const [heroDivHeight, setHeroDivHeight] = useState();

  const { setHeroHeight, tiktokCampaignMailSent } = useContext(ThemeContext);

  useEffect(() => {
    setHeroHeight(heroDivHeight);
  }, [heroDivHeight, width]); //eslint-disable-line

  return (
    <div className={classnames(styles.heroimageWrap, styles['imageBottom'])}>
      <section
        className={classnames(styles.heroimage, styles.heroImageTop)}
        ref={el => {
          if (!el) return;

          let prevValue = JSON.stringify(el.getBoundingClientRect());
          const handle = setInterval(() => {
            let nextValue = JSON.stringify(el.getBoundingClientRect());
            if (nextValue === prevValue) {
              clearInterval(handle);
              setHeroDivHeight(el.getBoundingClientRect().height);
            } else {
              prevValue = nextValue;
            }
          }, 100);
        }}
      >
        <div
          style={{
            backgroundColor: tiktokCampaignMailSent ? '#FF614D' : shapeColour,
          }}
          className={styles.content}
        >
          <div className={classnames('container', styles.container)}>
            <div className={styles.text}>
              {!tiktokCampaignMailSent && (
                <>
                  <h2>{subheadline}</h2>
                  <h1>
                    {headlinePart1} <strong>{headlinePart2}</strong> <br />
                    {headlinePart3}
                  </h1>
                  <CountdownTimer endDate={countdownTimerEndDate} />
                </>
              )}

              {!tiktokCampaignMailSent && (
                <Button
                  submit
                  className={styles.button}
                  variant="neutral"
                  capitalized
                  size="md"
                  onClick={() => {
                    scroller.scrollTo('regForm', {
                      duration: 800,
                      delay: 0,
                      smooth: 'easeInOutQuart',
                    });
                  }}
                >
                  Register Today
                </Button>
              )}
              {tiktokCampaignMailSent && (
                <>
                  <h2>{subheadline}</h2>
                  <h1>
                    <strong>THANK YOU </strong> FOR <br /> YOUR INTEREST
                  </h1>
                  <p>We have received your details.</p>
                  <p>
                    Explore Wilton Greens today and discover a community where
                    nature thrives.
                  </p>
                </>
              )}
              {tiktokCampaignMailSent && (
                <Button
                  variant="primary"
                  capitalized
                  size="md"
                  link="/land-for-sale"
                >
                  LAND FOR SALE
                </Button>
              )}
            </div>

            <div className={styles.image}>
              <img src={image.mediaItemUrl} alt="" />
            </div>
          </div>
        </div>

        {width > 768 ? (
          <Wave
            className={styles.wave}
            fill={tiktokCampaignMailSent ? '#FF614D' : shapeColour}
          />
        ) : (
          <WaveMobile
            className={styles.wave}
            fill={tiktokCampaignMailSent ? '#FF614D' : shapeColour}
          />
        )}
      </section>
    </div>
  );
};

export default HeroCounter;
