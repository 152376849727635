export const formatDate = date => {
  let dateSplit = date.split(' ');
    let dateItems = dateSplit[0].split('-');
    const day = dateItems[2].split('T');
    let months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    return (
      day[0] + ' ' + months[Number(dateItems[1]) - 1] + ' ' + dateItems[0]
    );
};
