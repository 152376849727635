import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import classnames from 'classnames';
import ScrollToTop from 'utils/ScrollToTop';
import { formatDate } from 'utils/formatDate';
import {
  ArticleContent,
  ArticleCategories,
  ArticlePagination,
} from 'components/ArticleContent';
import HeadingArticle from 'components/HeadingArticle';

import styles from './post.module.scss';

/**
 * GraphQL post query that takes a post slug as a filter
 * Returns the title, content and author of the post
 */
const POST_QUERY = gql`
  query PostQuery($filter: String!) {
    postBy(slug: $filter) {
      id
      title
      excerpt
      modified
      date
      content
      featuredImage {
        mediaItemUrl
      }
      categories {
        edges {
          node {
            categoryId
          }
        }
      }
    }
    posts {
      edges {
        node {
          id
          slug
          title
          categories {
            nodes {
              name
              slug
              categoryId
            }
          }
          excerpt
          featuredImage {
            mediaItemUrl
          }
        }
      }
    }
    categories {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

/**
 * Fetch and display a Post
 */
export const Post = props => {
  const [state, setState] = useState({
    post: null,
    categories: null,
    posts: null,
    themeSettings: null,
  });

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executePostQuery = async () => {
      const { match, client } = props;
      const filter = match.params.slug;
      const result = await client.query({
        query: POST_QUERY,
        variables: { filter },
      });
      if (!requestCancelled) {
        const post = result.data.postBy;
        const posts = result.data.posts;
        const categories = result.data.categories;

        setState({ post, posts, categories });
      }
    };

    // Execute post query, process the response and set the state
    executePostQuery();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props.match]); // eslint-disable-line

  const { post, posts, categories } = state;
  if (!post) {
    return null;
  } else {
    const findPostIndex = id => {
      let index;
      // eslint-disable-next-line
      posts.edges.map((post, i) => {
        if (post.node.id === id) {
          index = i;
        }
      });
      return index;
    };

    const postIndex = findPostIndex(post.id);

    const postLength = posts.edges.length - 1;

    const siblingPosts = [
      postIndex < 1 ? posts.edges[postLength] : posts.edges[postIndex - 1],
      postIndex >= postLength ? posts.edges[0] : posts.edges[postIndex + 1],
    ];

    const findSimilarPosts = selectedPostCategories => {
      const results = [];
      // eslint-disable-next-line
      posts.edges.map((individualPost, i) => {
        // eslint-disable-next-line
        individualPost.node.categories.nodes.map(individualPostCat => {
          if (
            individualPostCat.categoryId ===
              selectedPostCategories.edges[0].node.categoryId &&
            i !== postIndex
          ) {
            results.push(individualPost);
          }
        });
      });

      return results;
    };

    const similarPosts = findSimilarPosts(post.categories);
    return (
      <>
        <ScrollToTop />
        <HeadingArticle
          title={post.title}
          date={formatDate(post.date)}
          bgcolor={'#fff'}
          color={'#004E44'}
          goBack={'/news-and-events'}
        />
        <section className={classnames(styles.article, 'container')}>
          <div className={styles.wrap}>
            <div className={styles.inner}>
              <div className={styles.content}>
                <ArticleContent
                  title={post.title}
                  hero={post.featuredImage.mediaItemUrl}
                  excerpt={post.excerpt}
                  copy={post.content}
                  // themeSettings={themeSettings}
                />
              </div>
              <div className={styles.categories}>
                <ArticleCategories
                  similarStories={similarPosts}
                  categories={categories}
                />
              </div>
              <div className={styles.pagination}>
                <ArticlePagination
                  nextArticle={siblingPosts[0]}
                  prevArticle={siblingPosts[1]}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default withApollo(Post);
