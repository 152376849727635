import React from 'react';

import { ReactComponent as WaveBottom } from 'static/images/layout/wave-bottom-900.svg';

import styles from './Statistics.module.scss';

export const Statistics = ({ ...props }) => {
  const { zIndex, note, statistic } = props;

  return (
    <section
      className={styles.statistics}
      style={{ zIndex: zIndex ? zIndex : null }}
    >
      <WaveBottom className={styles.waveBottom} />

      <div className="container">
        {statistic.map((item, i) => {
          return (
            <div key={`statistic-${i}`} className={styles.item}>
              <h3 className={styles.heading}>{item.heading}</h3>
              <div className={styles.stat}>{item.stat}</div>
              <div className={styles.paragraph}>{item.paragraph}</div>
            </div>
          );
        })}
      </div>
      <div className="container">
        <div className={styles.note}>{note}</div>
      </div>
    </section>
  );
};

export default Statistics;
