import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import Icon from 'components/Icon';

import styles from './HeadingArticle.module.scss';

export const HeadingArticle = ({ title, date, bgcolor, color, goBack }) => {
  return (
    <section className={classnames(styles.headingArticle, 'wrap__hundred')}>
      <div
        className={classnames(styles.wrap, 'container')}
        style={{ backgroundColor: bgcolor }}
      >
        <div className={classnames(styles.inner, 'wrap__xxl')}>
          <Link className={styles.goback} to={goBack}>
            <div className={styles.goback__icon}>
              <Icon name="goback" className={styles.goback__svg} />
            </div>
          </Link>
          <div className={styles.title}>
            <h1
              style={{ color }}
              className={'headings-font-display-4'}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <h4>{date}</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeadingArticle;
