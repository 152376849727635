import React, { useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import Swiper from 'react-id-swiper';
import styles from './Slider.module.scss';
import { extractVideoId } from 'js/utils/extractVideoId';

const Slider = ({ data }) => {
  const screenWidth = useWindowWidth();
  // const [activeIndex, setActiveIndex] = useState(0);
  const [mainSwiper, setMainSwiper] = useState(null);
  const [thumbSwiper, setThumbSwiper] = useState(null);

  const mainParams = {
    setWrapperSize: true,
    spaceBetween: 10,
    loop: false,
    getSwiper: setMainSwiper,
    navigation: {
      nextEl: '.mainSwiperButtonNext',
      prevEl: '.mainSwiperButtonPrev',
      clickable: true,
    },
    // on: {
    //   slideChange: function () {
    //     setActiveIndex(this.realIndex); // Update active index based on `realIndex` for proper synchronization
    //   },
    // },
  };

  const thumbParams = {
    setWrapperSize: true,
    spaceBetween: 0,
    slidesPerView: 4,
    loop: false,
    slideToClickedSlide: false,
    navigation: {
      nextEl: '.heroThumbSwiperButtonNext',
      prevEl: '.heroThumbSwiperButtonPrev',
      clickable: true,
    },
    getSwiper: setThumbSwiper,

    // on: {
    //   slideChange: function () {
    //     setActiveIndex(this.realIndex);
    //   },
    // },
  };

  const handleThumbnailClick = index => {
    if (mainSwiper && thumbSwiper) {
      mainSwiper.slideToLoop(index);
      thumbSwiper.slideToLoop(index);
      // setActiveIndex(index);
    }
  };

  const goNext = swiperType => {
    if (swiperType !== null) {
      swiperType.slideNext();
    }
  };

  const goPrev = swiperType => {
    if (swiperType !== null) {
      swiperType.slidePrev();
    }
  };
  const isMobile = screenWidth <= 600;
  return (
    <div className={styles.sliderWrapper}>
      {data && data?.length > 0 && (
        <div className={styles.largeSlider}>
          <Swiper {...mainParams}>
            {data?.map((slide, index) => {
              let video = null;
              if (slide?.type === 'video' && slide?.video) {
                video = extractVideoId(slide.video);
              }
              if (slide?.type === 'image') {
                return (
                  <div
                    className={styles.mainSlideItem}
                    key={`slide-${index}`}
                    style={{
                      background: `url(${slide?.image?.mediaItemUrl}) 0 0 no-repeat`,
                      backgroundSize: 'cover',
                      width: '100%',
                      height: isMobile ? '300px' : '589px',
                    }}
                  >
                    {/* <img
                      src={slide?.image?.mediaItemUrl}
                      alt="img"
                    /> */}
                  </div>
                );
              }
             
              return (
                <div
                  className={styles.mainSlideItem}
                  style={{
                    width: '100%',
                    height: '589px',
                  }}
                  key={`slide-${index}`}
                >
                  {video?.videoId &&
                    video?.platform?.toLowerCase() === 'youtube' && (
                      <iframe
                        title="youtube video"
                        width="100%"
                        height="100%"
                        display="block"
                        src={`https://www.youtube.com/embed/${video.videoId}?si=Tr-QwFFBAQvUpoP-`}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    )}
                  {video?.videoId &&
                    video?.platform?.toLowerCase() === 'vimeo' && (
                      <iframe
                        src={`https://player.vimeo.com/video/${video.videoId}`}
                        frameBorder="0"
                        allowFullScreen
                        title="video-iframe"
                      />
                    )}
                </div>
              );
            })}
          </Swiper>
          {data?.length > 1 && (
            <>
              <button
                className={styles.mainPrev}
                onClick={() => goPrev(mainSwiper)}
              ></button>
              <button
                className={styles.mainNext}
                onClick={() => goNext(mainSwiper)}
              ></button>
            </>
          )}
        </div>
      )}
      {data && data?.length > 0 && (
        <div className={styles.thumbSlider}>
          <Swiper {...thumbParams}>
            {data?.map((slide, index) => {
              return (
                <div
                  key={`slide-${index}`}
                  onClick={() => handleThumbnailClick(index)}
                  className={styles.imgThumbnail}
                >
                  {slide?.type?.toLowerCase() === 'video' && (
                    <div className={styles.videoPlay}></div>
                  )}
                  <img
                    src={slide?.image?.mediaItemUrl}
                    alt="img"
                  />
                </div>
              );
            })}
          </Swiper>
          {data?.length > 4 && (
            <>
              <button
                className={styles.thumbPrev}
                onClick={() => goPrev(thumbSwiper)}
              ></button>
              <button
                className={styles.thumbNext}
                onClick={() => goNext(thumbSwiper)}
              ></button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Slider;
