import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ScrollLock from 'react-scrolllock';
import { scroller } from 'react-scroll';

import Icon from 'components/Icon';

import styles from './Navigation.module.scss';

import { ReactComponent as Logo } from 'static/images/wiltongreens.svg';

export const LandingNavigation = ({ data }) => {
  return (
    <div className={styles.navbar}>
      <div className={classnames(styles.container, 'container')}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>

        <nav className={classnames(styles.nav)}>
          <ScrollLock isActive={false}>
            <div className={styles.inner}>
              <div className={styles.top}>
                <ul>
                  <li
                    onClick={() => {
                      scroller.scrollTo('regForm', {
                        duration: 800,
                        delay: 0,
                        smooth: 'easeInOutQuart',
                      });
                    }}
                    className={styles.register}
                  >
                    <span>Register</span>
                  </li>
                </ul>
              </div>
            </div>
          </ScrollLock>
        </nav>

        <div className={classnames(styles.phoneWrapper, styles.landing)}>
          <a href="tel:133188" className={styles.phone}>
            <Icon name="phone" className={styles.phoneicon} />
          </a>
          <Link to={data.link}>
            <Icon name="marker" className={styles.marker} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandingNavigation;
