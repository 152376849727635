import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './TextLeftImageRight.module.scss';
import BackButton from 'components/BackButton';
// import SpecialOfferImg from 'static/images/specialOffer.jpg';
// import MobileSpecialOfferImg from 'static/images/mobile-special-offer-img.jpg';
import Button from 'components/Button';

export const TextLeftImageRight = props => {
  const { button, detailsOne, detailsTwo, image, title } = props;
  const location = useLocation();
  const { builder, slug } = location.state || {
    builder: null,
    slug: null,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {(slug && builder) && (
          <div className={styles.mobileHeading}>
            <BackButton url={slug} title={`Back to ${builder}`} />
            {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
          </div>
        )}
      </div>
      <div className={styles.imageWrapMobile}>
        {image?.mediaItemUrl && (
          <img src={image.mediaItemUrl} alt="special-offer" />
        )}
      </div>
      <div className={styles.container}>
        <div className={styles.desktopBackBttn}>
          {(slug && builder) && (
            <BackButton url={slug} title={`Back to ${builder}`} />
          )}
        </div>
        <div className={styles.textAndImageBlock}>
          <div className={styles.textWrap}>
            {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
            {detailsOne && <div className={styles.text}>{detailsOne}</div>}
            {detailsTwo && <p className={styles.description}>{detailsTwo}</p>}
            {button?.title && button?.url && (
              <Button
                className={styles.viewBtn}
                variant="basic"
                link={button.url}
              >
                {button.title}
              </Button>
            )}
          </div>
          <div className={styles.imageWrap}>
            {image?.mediaItemUrl && <img src={image.mediaItemUrl} alt="img" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextLeftImageRight;
