import React from 'react';
import { Helmet } from 'react-helmet';
import IframeResizer from 'iframe-resizer-react';
import './styles.scss';

export const VrTour = props => {

  return (
    <section className="tourSection">
      <Helmet>
        <title itemProp="name" lang="en">
          Wilton Greens | VR Tour
        </title>
      </Helmet>
      <IframeResizer
        log
        src="https://www.51world.com.au/webcase/au1qxnkwe2mbjttq/"
        className="tour"

      />
    </section>
  );
};

export default VrTour;
