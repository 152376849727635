import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { withApollo } from 'react-apollo';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import Topbar from './Topbar';
import Navigation, { LandingNavigation } from './Navigation';

import styles from './Header.module.scss';

/**
 * GraphQL menu query
 * Gets the labels, types (internal or external) and URLs
 */
const HEADER_QUERY = gql`
  query HeaderQuery {
    themeSettings {
      acf_theme_header {
        editableRegionLeft {
          link
          newWindow
          text
          linkType
          triggerForm
        }
        editableRegionRight {
          link
          newWindow
          text
        }
        callUsText
        registerButtonText
      }
    }
    menus(where: { slug: "header-menu" }) {
      nodes {
        menuItems {
          edges {
            node {
              label
              target
              url
              childItems {
                edges {
                  node {
                    label
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      pageFields {
        enableLandingHeader
      }
    }
  }
`;

// Checks if urltype is internal or external
// const isExternal = urltype => urltype.includes('_blank');

export const Header = ({ ...props }) => {
  const [state, setState] = useState({
    headerTop: null,
    navItems: null,
    enableLandingHeader: false,
  });

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;
    const uri = window.location.pathname;
    const executeHeader = async () => {
      const { client } = props;
      const result = await client.query({
        query: HEADER_QUERY,
      });

      const pageResult = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });
    
      if (!requestCancelled) {
        const headerTop = result.data.themeSettings.acf_theme_header;
        const navItems = result.data.menus.nodes[0].menuItems.edges;
       
        setState({
          headerTop: headerTop,
          navItems: navItems,
          enableLandingHeader:
          uri === "/" ? false :  pageResult?.data?.pageBy?.pageFields?.enableLandingHeader,
        });
      }
    };

    // Execute the menu query, parse the result and set the state
    executeHeader();

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props.match]); // eslint-disable-line

  const { headerTop } = state;

  return (
    <>
      <header className={styles.header}>
        {state.headerTop && <Topbar data={headerTop} />}
        {state.navItems && !state.enableLandingHeader && (
          <Navigation data={state} headerTop={headerTop} />
        )}
        {state.enableLandingHeader && (
          <LandingNavigation data={headerTop.editableRegionRight} />
        )}
      </header>
    </>
  );
};

export default compose(withRouter, withApollo)(Header);
