import React from 'react';
import styles from './MasterplanImage.module.scss';

export const MasterplanImage = props => {
  const image = props?.image?.mediaItemUrl;
  return (
    <section className={styles.displayVillageImage}>
      {image && <img src={image} alt="icon" />}
    </section>
  );
};

export default MasterplanImage;
