import React, { useEffect, useState } from 'react';
import { withApollo } from 'react-apollo';
import { Helmet } from 'react-helmet';
import gql from 'graphql-tag';
import classnames from 'classnames';
import Listings from 'components/Listings';
import Categories from 'components/Categories';
import ScrollToTop from 'utils/ScrollToTop';
import styles from './newsandevents.module.scss';

const POST_QUERY = gql`
  query MyQuery($after: String) {
    posts(first: 12, after: $after) {
      edges {
        node {
          modified
          date
          excerpt
          slug
          featuredImage {
            mediaItemUrl
          }
          title
          categories {
            nodes {
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
    categories {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
  }
`;

export const Newsandevents = props => {
  const [state, setState] = useState({
    loading: false,
    categories: null,
    posts: null,
    pageInfo: null,
  });

  const loadMorePosts = async (requestCancelled, fromLoadMore) => {
    const { match, client } = props;
    const filter = match.params.slug;
    setState({
      ...state,
      loading: true,
    });
    const result = await client.query({
      query: POST_QUERY,
      variables: {
        filter,
        after: fromLoadMore ? state.pageInfo?.endCursor : null,
      },
    });
    // console.log(fromLoadMore, result);
    if (!requestCancelled) {
      const categories = result.data.categories;
      const posts = result.data.posts.edges;
      let newPosts = posts;
      if (fromLoadMore && state.posts?.length > 0) {
        newPosts = [...state.posts, ...posts];
      }
      setState({
        categories,
        posts: newPosts,
        pageInfo: result.data?.posts?.pageInfo,
        loading: false,
      });
    }
  }; // eslint-disable-line

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    // Execute post query, process the response and set the state
    loadMorePosts(requestCancelled);

    // cancel subscriptions and asynchronous tasks
    return () => {
      requestCancelled = true;
    };
  }, [props]); // eslint-disable-line

  const { posts, categories, loading, pageInfo } = state; // eslint-disable-line

  if (!posts) return null;

  return (
    <section className={classnames('container', styles.newsandevents)}>
      <ScrollToTop />
      <Helmet>
        <title itemProp="name" lang="en">
          Wilton Greens | News and Events
        </title>
      </Helmet>
      <div className={styles.headingSmall}>
        <h2 className={'font-base-sm'}>Community News</h2>
      </div>
      <Categories
        heading={'<span class="bold">News</span> and Events'}
        categories={categories}
      />
      <Listings
        loading={loading}
        posts={posts}
        pageInfo={pageInfo}
        loadMorePosts={loadMorePosts}
      />
    </section>
  );
};

export default withApollo(Newsandevents);
