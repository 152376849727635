export const do_inSiteReg = regdata => {
  return new Promise((resolve, reject) => {
    if (typeof window.isLog === 'function') {
      const insdata = { meta: { registration: 'Online' } };
      // console.log('inside insite', regdata);
  
      Object.keys(regdata).map((name, i) => {
        const value = regdata[name];
  
        switch (name) {
          case 'firstName':
            insdata['first_name'] = value;
            break;
          case 'lastName':
            insdata['last_name'] = value;
            break;
          case 'email':
            insdata['email'] = value;
            break;
          case 'phoneNumber':
            insdata['phone'] = value;
            break;
          default:
            insdata['meta'][name] = value;
        }
  
        return false;
      });
  
      window.isLog('Register User', insdata);
      resolve("register successfull")
    } else {
      reject("isLog function not available")
    }
  })
};
