import React, { useContext, useState, useEffect } from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';
import { Link } from 'react-router-dom';
import useWindowScroll from '@react-hook/window-scroll';

import { ThemeContext } from 'theme/theme';

import styles from './ViewButton.module.scss';
import { ReactComponent as ArrowOrange } from 'static/icons/arrow-orange.svg';
import { ReactComponent as ViewButtonShape } from 'static/images/page-link-shape.svg';

export const ViewButton = ({ text, link, colour }) => {
  const [isScrolled, setScrolled] = useState(false);
  const [width] = useWindowSize();
  const {
    heroHeight,
    footerFromTop,
    featureImageFromTop,
    featureImageHeight,
  } = useContext(ThemeContext);

  const scrollY = useWindowScroll(10);

  useEffect(() => {
    if (
      (!featureImageFromTop || width >= 768) &&
      scrollY > heroHeight &&
      scrollY < footerFromTop
    ) {
      setScrolled(true);
    } else if (
      featureImageFromTop &&
      width < 768 &&
      scrollY > featureImageFromTop - featureImageHeight &&
      scrollY < featureImageFromTop
    ) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, [scrollY]); //eslint-disable-line

  return (
    <Link
      to={`/${link}`}
      className={classnames(styles[colour], styles.viewButton, {
        [styles.active]: isScrolled,
      })}
    >
      <ViewButtonShape className={classnames(styles[colour], styles.viewButtonShape)}/>
      View <br />
      {text}
      <br />
      <span className={styles.orange}>Next</span>
      <br />
      <ArrowOrange className={styles.arrow} />
    </Link>
  );
};

export default ViewButton;
