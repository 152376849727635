import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import useWindowSize from '@react-hook/window-size';
import { ReactComponent as Wave } from 'static/images/layout/wave-bottom-900.svg';
import { ReactComponent as WaveMobile } from 'static/images/layout/wave-bottom-mobile.svg';
import styles from './HeroImage.module.scss';
import { ThemeContext } from 'theme/theme';

export const HeroImage = ({ ...props }) => {
  const {
    headlinePart1,
    headlinePart2,
    subheadline,
    shapeColour,
    image,
    illustration,
    layoutOptions,
    illustrationMarginRight,
  } = props;
  const breakPoint = 1024;
  const [width] = useWindowSize();
  const [heroDivHeight, setHeroDivHeight] = useState();

  const { setHeroHeight } = useContext(ThemeContext);

  // console.log(layoutOptions);

  useEffect(() => {
    setHeroHeight(heroDivHeight);
  }, [heroDivHeight, width]); //eslint-disable-line

  return (
    <div className={classnames(styles.heroimageWrap, styles[layoutOptions])}>
      <section
        className={classnames(
          styles.heroimage,
          layoutOptions !== 'imageBottom' ? styles.heroImageTop : null,
        )}
        ref={el => {
          if (!el) return;

          let prevValue = JSON.stringify(el.getBoundingClientRect());
          const handle = setInterval(() => {
            let nextValue = JSON.stringify(el.getBoundingClientRect());
            if (nextValue === prevValue) {
              clearInterval(handle);
              setHeroDivHeight(el.getBoundingClientRect().height);
            } else {
              prevValue = nextValue;
            }
          }, 100);
        }}
      >
        {layoutOptions === 'imageBottom' ? (
          <div
            style={{ backgroundColor: shapeColour }}
            className={styles.content}
          >
            <div className={classnames('container', styles.container)}>
              <div className={styles.text}>
                <h2>{subheadline}</h2>
                <h1>
                  {headlinePart1} <strong>{headlinePart2}</strong>
                </h1>
              </div>

              <div className={styles.image}>
                <img src={image.mediaItemUrl} alt="" />
              </div>
            </div>
          </div>
        ) : layoutOptions === 'imageTop' ? (
          <div
            style={{ backgroundColor: shapeColour }}
            className={styles.contentBottom}
          >
            <div className={classnames('container', styles.container)}>
              <div className={styles.text}>
                <h2 className={styles.subHeadlineBottom}>{subheadline}</h2>
                <h1>
                  {headlinePart1} <strong>{headlinePart2}</strong>
                </h1>
              </div>

              <div
                className={classnames(
                  styles.imageTopLayout,
                  styles.imageTopLayoutOne,
                )}
              >
                <div className={styles.imageTopSvg}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1102 921"
                  >
                    <defs>
                      <clipPath id="hero-image-text-mask-top-one">
                        <path
                          d="M1102 921v-.9h-4.4c1.5.3 2.9.6 4.4.9z"
                          fill="#d8d8d8"
                        />
                        <path d="M968.7 0H95.1C-19.4 52.7-30.5 148.8 61.7 288.3 276 612.6 621.4 823.2 1097.6 920.1h4.4V0H968.7z" />
                      </clipPath>
                    </defs>
                    <image
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMinYMin slice"
                      xlinkHref={image.mediaItemUrl}
                      clipPath="url(#hero-image-text-mask-top-one)"
                    />
                  </svg>
                </div>
                {illustration && width > breakPoint ? (
                  <div
                    className={styles.illustration}
                    style={
                      illustrationMarginRight
                        ? { marginRight: `${illustrationMarginRight}px` }
                        : null
                    }
                  >
                    <img src={illustration.mediaItemUrl} alt="" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : layoutOptions === 'imageTopVersionTwo' ? (
          <div
            style={{ backgroundColor: shapeColour }}
            className={styles.contentBottom}
          >
            <div className={classnames('container', styles.container)}>
              <div className={classnames(styles.text, styles.textTopTwo)}>
                <h2 className={styles.subHeadlineBottom}>{subheadline}</h2>
                <h1>
                  {headlinePart1} <strong>{headlinePart2}</strong>
                </h1>
              </div>

              <div
                className={classnames(
                  styles.imageTopLayout,
                  styles.imageTopLayoutTwo,
                )}
              >
                <div className={classnames(styles.imageTopSvg)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1102 921"
                  >
                    <defs>
                      <clipPath id="hero-image-text-mask-top-two">
                        <path d="M1059.9 0H483.4C335.8 51.7 203.6 147.8 86.8 288.5-179.6 609.5 167.1 820.3 1127 921V0h-67.1z" />
                      </clipPath>
                    </defs>
                    <image
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMinYMin slice"
                      xlinkHref={image.mediaItemUrl}
                      clipPath="url(#hero-image-text-mask-top-two)"
                    />
                  </svg>
                </div>
                {illustration && width > breakPoint ? (
                  <div
                    className={classnames(
                      styles.illustration,
                      styles.illustrationTwo,
                    )}
                    style={
                      illustrationMarginRight
                        ? { marginRight: `${illustrationMarginRight}px` }
                        : null
                    }
                  >
                    <img src={illustration.mediaItemUrl} alt="" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{ backgroundColor: shapeColour }}
            className={styles.contentBottom}
          >
            <div className={classnames('container', styles.container)}>
              <div className={classnames(styles.text, styles.textTopTwo)}>
                <h2 className={styles.subHeadlineBottom}>{subheadline}</h2>
                <h1>
                  {headlinePart1} <strong>{headlinePart2}</strong>
                </h1>
              </div>

              <div
                className={classnames(
                  styles.imageTopLayout,
                  styles.imageTopLayoutThree,
                )}
              >
                <div className={classnames(styles.imageTopSvg)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2000 1242"
                  >
                    <defs>
                      <clipPath id="hero-image-text-mask-top-three">
                      <path d="M513.8 0C180.8 186.7 9.5 412.1.5 675c-18 526 648.5 685.6 1999.5 478.8V0C1233.9.9 738.5.9 513.8 0z" fill="#d8d8d8"/>
                      </clipPath>
                    </defs>
                    <image
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMinYMin slice"
                      xlinkHref={image.mediaItemUrl}
                      clipPath="url(#hero-image-text-mask-top-three)"
                    />
                  </svg>
                </div>
                {illustration && width > breakPoint ? (
                  <div
                    className={classnames(
                      styles.illustration,
                      styles.illustrationTwo,
                    )}
                    style={
                      illustrationMarginRight
                        ? { marginRight: `${illustrationMarginRight}px` }
                        : null
                    }
                  >
                    <img src={illustration.mediaItemUrl} alt="" />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )}
        {width > 768 ? (
          <Wave className={styles.wave} fill={shapeColour} />
        ) : (
          <WaveMobile className={styles.wave} fill={shapeColour} />
        )}

        {illustration && width < breakPoint ? (
          <div
            className={classnames(styles.illustration)}
            style={
              illustrationMarginRight
                ? { marginRight: `${illustrationMarginRight}px` }
                : null
            }
          >
            <img src={illustration.mediaItemUrl} alt="" />
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default HeroImage;
