import React from 'react';
import classnames from 'classnames';
import styles from './FilterItem.module.scss';

export const FilterItem = ({ filterName, filter, setFilter, title, data }) => {
  return (
    <div className={styles.filterItem}>
      <h2>{title}</h2>
      <div className={styles.filterItemData}>
        <button
          type="button"
          onClick={() => {
            if (filterName === 'specialOffer') {
              const specialOffer = filter?.specialOffer;
              setFilter({
                ...filter,
                [filterName]: specialOffer === 'all' ? "yes" : "all",
              });
            } else {
              setFilter({
                ...filter,
                [filterName]: 'all',
              });
            }
          }}
          className={classnames(
            styles.all,
            filter[filterName] === 'all' ? styles.active : null,
          )}
        >
          All
        </button>
        {data?.length > 0 && (
          <div className={styles.count}>
            {data?.map((item, index) => {
              return (
                <button
                  key={`item-${index}`}
                  onClick={() => {
                    setFilter({
                      ...filter,
                      [filterName]: item,
                    });
                  }}
                  type="button"
                  className={classnames(
                    styles.countItem,
                    filter[filterName] === item ? styles.active : null,
                  )}
                >
                  {item}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterItem;
