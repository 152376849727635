import React, { useState, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import { Helmet } from 'react-helmet';
import PropertyApp, { PropertyAppProvider } from 'react-property-app';
// import HeadingPropertyApp from 'components/HeadingPropertyApp';

// eslint-disable-next-line
import styles from './landForSale.module.scss';

const PAGE_QUERY = gql`
  query PageQuery($uri: String!) {
    pageBy(uri: $uri) {
      content
      title
    }
  }
`;

export const LandForSale = props => {
  const [loading, setLoading] = useState(true);
  const [pageState, setPageState] = useState({
    page: null,
  });

  useEffect(() => {
    let requestCancelled = false;
    const executePageQuery = async () => {
      const { match, client } = props;
      const uri = match.url.substr(1);
      const result = await client.query({
        query: PAGE_QUERY,
        variables: { uri },
      });

      if (!requestCancelled) {
        const page = result.data.pageBy;
        setPageState({ ...pageState, page });
      }
      setLoading(false);
    };

    executePageQuery();

    return () => {
      requestCancelled = true;
    };
  }, [props]); //eslint-disable-line

  if (loading) return null;
  
  return (
    <PropertyAppProvider>
      <Helmet>
        <title itemProp="name" lang="en">
          Wilton Greens | Land for sale
        </title>
      </Helmet>
      {/* <HeadingPropertyApp
        headingLarge='<span class="bold">Land</span> for Sale'
      /> */}
      <div className='landForSale'>
        <PropertyApp />
      </div>
    </PropertyAppProvider>
  );
};

export default withApollo(LandForSale);
