import React from 'react';
import { Link } from 'react-router-dom';

import { stringSlice } from 'utils/stringSlice';

import styles from './ArticleCategories.module.scss';

export const ArticleCategories = ({ similarStories, categories }) => {

  return (
    <section className={styles.articleCategories}>
      <div className={styles.articleCategories__categories}>
        <h3 className={'font-base-sm bold'}>Categories</h3>
        <div className={styles.search}>
          <div className={styles.selectWrapper}>
            <ul>
              {categories.edges.map((category, i) => {
                return (
                  <li
                    className={'font-base-sm'}
                    key={`Category-list-${i}`}
                    value={category.node.slug}
                  >
                    <Link to={`/category/${category.node.slug}`}>
                      {category.node.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {similarStories.length ? (
        <div className={styles.articleCategories__similarStories}>
          <h3>Similar Stories</h3>
          <div className={styles.inner}>
            {similarStories.map((story, i) => {
              let title = stringSlice(story.node.title, 50);
              let excerpt = stringSlice(story.node.excerpt, 150);

              if (i < 2) {
                return (
                  <Link key={`similar-story-${i}`} to={story.node.slug}>
                    <h6
                      dangerouslySetInnerHTML={{
                        __html: story.node.categories.nodes[0].name,
                      }}
                    ></h6>
                    <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                    <p dangerouslySetInnerHTML={{ __html: excerpt }}></p>
                  </Link>
                );
              }
              return null;
            })}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default ArticleCategories;
