import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import useScrollPosition from '@react-hook/window-scroll';
import classnames from 'classnames';
import Button from 'components/Button';
import Icon from 'components/Icon';

import { ThemeContext } from 'theme/theme';

import footerQuery from 'graphqlQueries/footerQuery';

import { ReactComponent as Wave } from 'static/images/layout/wave-footer.svg';
import { ReactComponent as Marker } from 'static/icons/marker.svg';
import { ReactComponent as Risland } from 'static/images/risland.svg';
import illustration from 'static/images/illustration.png';

import styles from './Footer.module.scss';

const FOOTER_QUERY = footerQuery;

const Footer = React.memo(props => {
  const scrollY = useScrollPosition(60);
  const { toggleModalForm, setFormTypeRegister, setFooterFromTop } = useContext(
    ThemeContext,
  );
  const footer = useRef();
  const [footerState, setFooterState] = useState({ current: null });
  const [state, setState] = useState({
    footerInfo: [],
    stickyFooterInfo: [],
    landSuburbsPanel: [],
  });

  const onClick = () => {
    toggleModalForm();
    setFormTypeRegister();
  };

  useEffect(() => {
    // Cancel all subscriptions and asynchronous tasks to prevent memory leak
    let requestCancelled = false;

    const executeMenu = async () => {
      const { client } = props;
      const footerResult = await client.query({
        query: FOOTER_QUERY,
      });

      if (!requestCancelled) {
        const footerInfo = footerResult.data.footer.acf_theme_footer;
        const stickyFooterInfo =
          footerResult.data.stickyFooter.acf_theme_footer_sticky;
        const landSuburbsPanel =
          footerResult.data.landSuburbsPanel.land_suburbs_panel;
        setState({ footerInfo, stickyFooterInfo, landSuburbsPanel });
      }
    };

    executeMenu();

    return () => {
      requestCancelled = true;
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    setFooterState(footer);
  }, [footer]); //eslint-disable-line

  useEffect(() => {
    if (footerState.current) {
      setFooterFromTop(
        footerState.current.offsetTop - footerState.current.offsetHeight,
      );
    }
  }, [footerState.current]); //eslint-disable-line

  const { footerInfo, stickyFooterInfo } = state;
  if (footerInfo.length <= 0) return null;
  const pageSlugs = ['our-community', 'lifestyle', 'location', 'vision'];
  const pageSlug = props.pageSlug;
  return (
    <>
      <footer className={styles.footer} ref={footer}>
        {pageSlugs.indexOf(pageSlug) === -1 && (
          <div className={styles.footer_top}>
            <Wave className={styles.wave} />

            <span>{footerInfo.keyMessage}</span>
          </div>
        )}
        {pageSlugs.indexOf(pageSlug) !== -1 && (
          <div className={styles.prefooter}>
            <div className={styles.prefootercontainer}>
              <h2>{state.landSuburbsPanel.headlineprimary}</h2>
              <h3>{state.landSuburbsPanel.headlinesecondary}</h3>
              <p>{state.landSuburbsPanel.content}</p>
              {state.landSuburbsPanel.suburblist?.length > 0 && (
                <div className={styles.suburblist}>
                  {state.landSuburbsPanel.suburblist.map((item, index) => {
                    return (
                      <div
                        className={styles.suburbItem}
                        key={`suburb-${index}`}
                      >
                        <Link to={item.linkurl}>{item.title}</Link>
                      </div>
                    );
                  })}
                </div>
              )}
              {state.landSuburbsPanel?.button?.linkurl && (
                <Button
                  size="sm"
                  variant="secondary"
                  link={state.landSuburbsPanel.button.linkurl}
                >
                  {state.landSuburbsPanel.button.label}
                </Button>
              )}
            </div>
          </div>
        )}
        <div
          className={styles.footer_main}
          style={{ backgroundImage: `url(${illustration})` }}
        >
          <div className="container">
            <div>
              <div className={styles.footer_col}>
                <div className={styles.talktous}>
                  {footerInfo.heroDetails.headlinePart1}
                  <div>{footerInfo.heroDetails.headlinePart2}</div>
                </div>
              </div>
              <div className={styles.footer_col}>
                <div className={styles.explore}>
                  {footerInfo.heroDetails.paragraph}
                </div>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={onClick}
                  className={styles.register}
                >
                  {footerInfo.heroDetails.button1Label}
                </Button>
                <Button  className={styles.call} size="sm" variant="neutral" link="tel:133188">
                  {footerInfo.heroDetails.button2Label}
                </Button>
              </div>
              <div className={styles.footer_col}>
                <div className={styles.location}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: footerInfo.heroDetails.contactDetails,
                    }}
                  ></div>
                  <p>
                    <a
                      href={footerInfo.heroDetails.locationMapLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.location_link}
                    >
                      <span> {footerInfo.heroDetails.locationMapLabel} </span>
                      <Marker className={styles.marker} />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.footer_bottom}>
          <div className="container">
            <div>
              <div className={styles.footer_col}>
                <div className={styles.legal}>
                  <Link to={footerInfo.termsAndLogos.disclaimerLink.uri}>
                    Disclaimer{' '}
                  </Link>
                  <Link to={footerInfo.termsAndLogos.termsLink.uri}>Terms</Link>
                  <Link to={footerInfo.termsAndLogos.privacyLink.uri}>
                    Privacy
                  </Link>
                </div>
              </div>
              <div className={styles.footer_col}>
                <div className={styles.copyright}>
                  <Risland className={styles.risland} />{' '}
                  {footerInfo.termsAndLogos.copyright}
                </div>
              </div>
              <div className={styles.footer_col}>
                <div className={styles.socials}>
                  {footerInfo.termsAndLogos.instagramLink ? (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={footerInfo.termsAndLogos.instagramLink.url}
                      className={styles.icon}
                    >
                      <Icon name="instagram" className={styles.insta} />
                    </a>
                  ) : null}
                  {footerInfo.termsAndLogos.facebookLink ? (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={footerInfo.termsAndLogos.facebookLink.url}
                      className={styles.icon}
                    >
                      <Icon name="fb" className={styles.fb} />
                    </a>
                  ) : null}
                  {footerInfo.termsAndLogos.tiktokLink ? (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={footerInfo.termsAndLogos.tiktokLink.url}
                      className={styles.icon}
                    >
                      <Icon name="tiktok" className={styles.tiktok} />
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className={styles.stickyfooter}>
        <div
          className={classnames(styles.stickyfooter_inner, {
            [styles.sticky]: scrollY > 100,
          })}
        >
          <div className="container">
            <div>
              <div className={styles.stickyleft}>
                {stickyFooterInfo.registerKeyMessage}
              </div>
              <div className={styles.stickyright}>
                <Button
                  size="sm"
                  variant="primary"
                  className={styles.registerbutton}
                  onClick={onClick}
                >
                  {stickyFooterInfo.registerButtonLabel}
                </Button>
                <Button size="sm" variant="neutral" link="tel:133188">
                  Call on 133 188
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default withApollo(Footer);
