import React from 'react';

import CoordMap from './CoordMap';
import MarkersMap from './MarkersMap';

export const Map = ({ levels, ...props }) => {
  // remove levels without images

  levels = levels.filter(level => level.image);
  const [level] = levels;

  const hasMarkers = level.markers && level.markers.length;

  if (hasMarkers) return <MarkersMap {...props} levels={levels} />;
  return <CoordMap {...props} levels={levels} />;
};

export default Map;
