import React, { useState } from 'react';
import classnames from 'classnames';

import Modal from 'components/Modal';

import styles from './VideoPopUpModal.module.scss';

import playButton from 'static/icons/play.svg';
// import playButtonWhite from 'static/icons/play-white.svg';

export const VideoPopUpModal = props => {
  const [isOpen, setOpen] = useState(false);
  //const [reactPlayer, setReactPlayer] = useState(null);

  const {
    videoScreenShot,
    headlinePart1,
    headlinePart2,
    paragraph,
    icon,
    playButtonText,
    videoLink,
    zIndex,
  } = props ? props : null;

  // const playVideo = () => {
  // console.log(reactPlayer.current);
  //reactPlayer.current.play();
  // }

  const toggleOpen = () => {
    setOpen(!isOpen);
  };
  // if (!videoLink) return null;

  return (
    <>
      <section
        className={classnames(
          { [styles.withoutVideo]: !videoLink },
          styles.videoPopUpModal,
        )}
        style={{ zIndex: zIndex ? zIndex : null }}
      >
        <div className={classnames(styles.container, 'container')}>
          <div className={styles.icon}>
            <img src={icon.mediaItemUrl} alt="" />
          </div>
          <h2 className={classnames('headings-font-display-2')}>
            <span className={'bold'}>{headlinePart1}</span>
            <br />
            {headlinePart2}
          </h2>
          <p dangerouslySetInnerHTML={{ __html: paragraph }}></p>
          {playButtonText && (
            <div className={styles.play} onClick={toggleOpen}>
              <div className={styles.playIcon}>
                <img src={playButton} alt="" />
              </div>
              <div className={styles.playText}>{playButtonText}</div>
            </div>
          )}
        </div>
        <div className={styles.image}>
          <div className={styles.imageSvg}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1046 818">
              <defs>
                <clipPath id="image-text-mask">
                  <path
                    d="M1046 818c-379 0-438 0-964.1-.1-249-325.2 72.5-597.8 964.6-817.9-.5 104.9-.5 513-.5 818z"
                    fill="#b1dbf1"
                  />
                </clipPath>
              </defs>
              <image
                width="100%"
                height="100%"
                preserveAspectRatio="xMinYMin slice"
                xlinkHref={videoScreenShot.mediaItemUrl}
                clipPath="url(#image-text-mask)"
              />
            </svg>
          </div>
        </div>
      </section>
      <Modal isOpen={isOpen} setOpen={setOpen}>
        <div
          className="video"
          style={{
            position: 'relative',
            paddingBottom: '54.25%' /* 16:9 */,
            height: 0,
          }}
        >
          {/* <div className={styles.videoScreen} >
            <div className={styles.videoScreenOverlay}></div>
            <div className={styles.play} onClick={playVideo}>
              <div className={styles.playIcon}>
                <img src={playButtonWhite} alt="" />
              </div>
              <div className={styles.playText}>{playButtonText}</div>
            </div>
          </div>  */}

          {/* <ReactPlayer
            url={`https://player.vimeo.com/video/${videoLink}`}
            width="100%"
            height="100%"
            style={{ position: 'absolute' }}
            controls={true}
            config={{
              vimeo: {
                playerOptions: {
                  url: `https://player.vimeo.com/video/${videoLink}`,
                },
              },
            }}
          /> */}
          {videoLink && (
            <iframe
              src={`https://player.vimeo.com/video/${videoLink}`}
              frameBorder="0"
              allowFullScreen
              title="video-iframe"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default VideoPopUpModal;
