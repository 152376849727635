import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import classnames from 'classnames';

import Icon from 'components/Icon';

import styles from './MapLocation.module.scss';

export const MapLocation = ({
  map,
  heading,
  subHeading,
  copy,
  googleMapLink,
}) => {
  const [zoomed, setZoomed] = useState(false);
  const toggleZoom = () => {
    setZoomed(!zoomed);
  };

  useEffect(() => {
    setZoomed(true)
  }, []);

  return (
    <section
      className={classnames(styles.mapLocation, 'wrap__max')}
      id="location-map"
    >
      <div className={classnames('wrap__xxl', styles.inner)}>
        <div className={styles.header}>
          <Link to="amenities" smooth={true} offset={-176} duration={500}>
            <h2 className={styles.title}>Amenities</h2>
          </Link>
          <h2 className={styles.title}>Location</h2>
        </div>
        <div className={styles.map}>
          <div className={styles.mapInner}>
            <div className={styles.zoom} onClick={toggleZoom}>
              <div className={styles.line}></div>
              <div
                className={classnames(
                  styles.line,
                  zoomed ? styles.rotated : null,
                )}
              ></div>
            </div>
            <div
              className={classnames(
                styles.holder,
                zoomed ? styles.zoomed : null,
              )}
              style={{ backgroundImage: `url('${map}')` }}
            ></div>
          </div>
        </div>
        <div className={styles.content}>
          {
            heading &&
            <h2
              className={classnames(styles.heading, 'font-content-description')}
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          }
          {
            subHeading &&
            <h3
              className={classnames(styles.subHeading, 'headings-font-display-8')}
              dangerouslySetInnerHTML={{ __html: subHeading }}
            />
          }
          <div
            className={classnames(styles.copy, 'font-base')}
            dangerouslySetInnerHTML={{ __html: copy }}
          ></div>
          <div>
            <a
              href={googleMapLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classnames(styles.viewMap, 'font-base')}
            >
              <span className="link">View map and directions </span>
              <Icon name="arrow" className={styles.arrow} />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MapLocation;
