import gql from 'graphql-tag';

// GraphQL page query that takes a page slug as a uri
// Returns the title and content of the page

const FOOTER_QUERY = gql`
  query FooterQuery {
    footer {
      acf_theme_footer {
        heroDetails {
          button1Label
          button2Label
          contactDetails
          fieldGroupName
          headlinePart1
          headlinePart2
          locationMapLabel
          locationMapLink
          paragraph
        }
        termsAndLogos {
          copyright
          disclaimerLink {
            ... on Page {
              uri
            }
          }
          developerLogo {
            mediaItemUrl
          }
          privacyLink {
            ... on Page {
              id
              uri
            }
          }
          termsLink {
            ... on Page {
              id
              uri
            }
          }
          facebookLink {
            url
          }
          instagramLink {
            url
          }
          tiktokLink {
            url
          }
        }
        keyMessage
      }
    }
    stickyFooter {
      acf_theme_footer_sticky {
        registerButtonLabel
        registerKeyMessage
      }
    }
    landSuburbsPanel {
      land_suburbs_panel {
        button {
          label
          linkurl
        }
        content
        headlinesecondary
        headlineprimary
        suburblist {
          linkurl
          title
        }
      }
    }
  }
`;

export default FOOTER_QUERY;
