import React, { useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { ThemeContext } from 'theme/theme';

import styles from './Image.module.scss';

export const Image = ({ ...props }) => {
  const { mainImage, size, illustration, zIndex, imagePosition } = props;
  const { setFeatureImage } = useContext(ThemeContext);
  const image = useRef();

  useEffect(() => {
    if (image.current) {
      setFeatureImage(image.current.offsetTop, image.current.offsetHeight);
    }
  }, [image]); //eslint-disable-line

  useEffect(() => {
    return () => {
      setFeatureImage(null, null);
    };
  }, []); //eslint-disable-line

  return (
    <section
      ref={image}
      className={classnames(styles.image, {
        [styles.image__small]: size === 'small',
      })}
      style={{
        backgroundImage: `url(${mainImage.mediaItemUrl})`,
        zIndex: zIndex ? zIndex : null,
        backgroundPositionY: imagePosition ? imagePosition : null,
      }}
    >
      <div className={classnames('container', styles.container)}>
        {illustration && (
          <img
            src={illustration.sourceUrl}
            alt=""
            className={styles.illustration}
          />
        )}
      </div>
    </section>
  );
};

export default Image;
