import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player/vimeo';
import Modal from 'components/Modal';
import styles from './IntroNarrow.module.scss';
import ErrorBoundary from './ErrorBoundary';

import playButton from 'static/icons/play.svg';

export const IntroNarrow = ({ ...props }) => {
  const [isOpen, setOpen] = useState(false);

  const reactPlayer = useRef();
  const {
    introParagraph,
    paragraph,
    headingPart1,
    headingPart2,
    addVideoLink,
    videoLink,
    videoButtonText,
  } = props;

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <section className={styles.intronarrow}>
      <div className="container">
        <div className={styles.text}>
          {headingPart1 && (
            <h2 className={styles.heading}>
              {headingPart1} {headingPart2 && <span>{headingPart2}</span>}
            </h2>
          )}
          {introParagraph && (
            <p
              className={styles.intro}
              dangerouslySetInnerHTML={{ __html: introParagraph }}
            />
          )}
          {paragraph && (
            <p
              className={styles.paragraph}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          )}
          {addVideoLink && (
            <>
              <div className={styles.play} onClick={toggleOpen}>
                <div className={styles.playIcon}>
                  <img src={playButton} alt="" />
                </div>
                <div className={styles.playText}>{videoButtonText}</div>
              </div>
              <Modal isOpen={isOpen} setOpen={setOpen}>
                <div
                  className="video"
                  style={{
                    position: 'relative',
                    paddingBottom: '54.25%' /* 16:9 */,
                    height: 0,
                  }}
                >
                  {/* <div className={styles.videoScreen} >
                    <div className={styles.videoScreenOverlay}></div>
                    <div className={styles.play} onClick={playVideo}>
                      <div className={styles.playIcon}>
                        <img src={playButtonWhite} alt="" />
                      </div>
                      <div className={styles.playText}>{playButtonText}</div>
                    </div>
                  </div>  */}
                  <ErrorBoundary>
                    <ReactPlayer
                      ref={reactPlayer}
                      url={`https://player.vimeo.com/video/${videoLink}`}
                      width="100%"
                      height="100%"
                      style={{ position: 'absolute' }}
                      controls={true}
                      config={{
                        vimeo: {
                          playerOptions: {
                            url: `https://player.vimeo.com/video/${videoLink}`,
                          },
                        },
                      }}
                    />
                  </ErrorBoundary>
                  {/* <iframe
                    src={`https://player.vimeo.com/video/${videoLink}`}
                    frameBorder="0"
                    allowFullScreen
                    title="video-iframe"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  ></iframe> */}
                </div>
              </Modal>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default IntroNarrow;
